import React, { Component } from 'react';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import pubsub from 'pubsub-js';

import * as qs from 'query-string';
import apiService from "../../apis/apiService"
import utilService from "../../common/utilService";

class FaqDetailPage extends Component {
    constructor(props) {
        super(props)
        console.log('this.props.location.search', this.props.location.search)
        this.seq = qs.parse(this.props.location.search).seq
        this.activePage = qs.parse(this.props.location.search).activePage || 1
        this.state = {
            faq: {},
        }
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        this.goPage(this.seq)

        var user = localStorage.user ? JSON.parse(localStorage.user) : null
        if (user && user.user_id) {
            pubsub.publish('refreshUserInfo');
        }
    };

    componentDidUpdate(prevProps, prevState) {
    }

    goList() {
        this.context.router.history.push('/faq?activePage=' + this.activePage);
    }

    goPage(seq) {
        apiService.getFaq(seq, (err, ret) => {
            if (!err) {
                this.setState({
                    faq: ret,
                })
                console.log(ret)
            }
        })
    }
    render() {
        var { faq } = this.state
        var lang = utilService.getCurrentLang()

        const replace = ((t) => {
            var t1 = String(t)
            t1 = t1.replace(/<p/g, '<div');
            t1 = t1.replace(/p>/g, 'div>');
            t1 = t1.replace(/<div><\/div>/g, '<br>');
            return t1
        })

        return (
            <div>
                <section className="contact padding-top-90 padding-bottom-90 primary_bg" id="contact">


                    <div className="row" style={{ maxWidth: 1220 }}>
                        <div className="col-md-12">


                            <div className="kv_csr" id="section_csr">
                                <div className="row">
                                    <h2 className="stt01"><i className="fa fa-quote-left" style={{ marginRight: 5 }}></i>FAQ</h2>
                                    <p className="stxt01">{utilService.getLangByCode('msg9', '자주하는 질문/답변을 확인해 보세요')}</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="row">
                                </div>
                                <div className="notice_detail">
                                    <table className="table">
                                        <colgroup>
                                            <col width="20%" />
                                            <col width="80%" />
                                        </colgroup>

                                        <thead>
                                            <tr>
                                                <td className="td_st1">{utilService.getLangByCode('label.type', '구　분')}</td>
                                                <td className="td_st3">{utilService.getFaqType(faq.faq_type)}</td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td className="td_st1">{utilService.getLangByCode('label.title', '제　목')}</td>
                                                <td className="td_st3">{lang == 'ko' ? faq.quest_note : faq.quest_note_en}</td>
                                            </tr>
                                            <tr>
                                                <td className="td_st1">{utilService.getLangByCode('label.regdate', '등록일')}</td>
                                                <td className="td_st3">{utilService.getDate(faq.reg_dt)}</td>
                                            </tr>
                                            <tr>
                                                <td className="td_st2 bdl">{utilService.getLangByCode('label.content', '내　용')}</td>
                                                <td className="td_st3">
                                                    <div dangerouslySetInnerHTML={{ __html: (lang == 'ko' ? replace(faq.answr_note) : replace(faq.answr_note_en)) }} />
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <footer className="table_footer_background2">
                                <div className="row">
                                    <center>
                                        <a type="button" className="btn btn-primary wd100" onClick={this.goList.bind(this)}>{utilService.getLangByCode('label.list', '목록')}</a>
                                    </center>
                                </div>
                            </footer>
                        </div>
                    </div>
                </section>
            </div>
        );
    };
};
FaqDetailPage.contextTypes = {
    router: PropTypes.object.isRequired
}
export default FaqDetailPage;
