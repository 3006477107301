export default () => {
    var $ = window.$
    // floating menu
    $(window).scroll(function(){
        floating();
    });
    $(window).load(function(){
        floating();
    });

    function floating(){
        var top = $(window).scrollTop();
        if(top > 50){
            $(".page #header").addClass("floating");


            /*$("#top_bar").css("height","0px");
            $(".navigation").css("margin-top","14px");
            $("#header").css("background-color","rgba(0,0,0,0.65)");
            $(".main_logo").css("width","80px");
            $(".navigation .menu").css("vertical-align","middle");
            $(".navi_sub").css("padding-top","29px");*/
        }else{

            $(".page #header").removeClass("floating");


            /*$("#top_bar").css("height","31px");
            $(".navigation").css("margin-top","-7px");
            $("#header").css("background-color","rgba(0,0,0,0)");
            $(".main_logo").css("width","110px");
            $(".navigation .menu").css("vertical-align","bottom");
            $(".navi_sub").css("padding-top","20px");*/
        }
    }

    $(".navigation .menu > ul > li").hover(function(){
        $(this).find('.navi_sub').show();
    },function(){
        $(this).find('.navi_sub').hide();
    });

    $("#navigation .menu > li").hover(function(){
        $(this).find('.navi_sub').show();
    },function(){
        $(this).find('.navi_sub').hide();
    });

    // m_menu setting
    //$(".m_menu_con").html('<div/>')
    $(".menu").clone().appendTo(".m_menu_con");

    // mobile menu
    function m_menu_open(){
        if(!$(".m_menu").is(':animated') ){
            $("#mask").show();
            $(".m_menu").velocity({right:0},300);
            $("body").addClass('o-hd');
        }
    }
    function m_menu_close(){
        if(!$(".m_menu").is(':animated') ){
            $(".m_menu").velocity({right:'-72%'},300);
            $("#mask").hide();
            $("body").removeClass('o-hd');
        }
    }
    $("#m_nav").click(function(event){
        event.preventDefault();
        m_menu_open();
    });
    $("#m_menu_close, #mask").click(function(event){
        event.preventDefault();
        m_menu_close();
    });

    $(".m_menu_top > li > a").click(function(event){
        m_menu_close()
    })

    $("#m_menu .menu > ul > li > a, #m_menu .menu > li > a").click(function(event){
        event.preventDefault();

        // 다른 하위 뎁스 닫기
        var this_idx = $(this).parent().index();
        close_open_menu(this_idx);
        var child_ul = $(this).next('.navi_sub');
        if( !child_ul.is(":animated") ){
            child_ul.slideToggle(300);
            $(this).parent().toggleClass('open');
        }
    });

    $("#btn_back").click(function(event){
        event.preventDefault();
        window.history.back();
    });


    function close_open_menu(idx){
        $("#m_menu .menu > ul > li").each(function(){
            var ul_menu = $(this).children('.navi_sub'),
                child_idx = $(this).index();
            if(idx != child_idx && ul_menu.css("display") == "block" ){
                ul_menu.slideUp(300);
                $(this).removeClass('open');
            }
        });
    }

    // mobile active page open
    $("#m_menu .menu > ul > li > .navi_sub > ul > li").each(function(){
        if( $(this).hasClass('act') ){
            $(this).parent().parent().show();
            $(this).parent().parent().parent().addClass('open');
        }
    });

    $("#ic_up").click(function(event){
        event.preventDefault();
        $('html,body').animate({ scrollTop : 0 }, 700);
    });
}