import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';
import MobileDetect from 'mobile-detect'
import pubsub from 'pubsub-js';

import MenuInitRun from './MenuInit.run';
import apiService from "../../apis/apiService";
import utilService from "../../common/utilService";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.user ? JSON.parse(localStorage.user) : null,
            md: new MobileDetect(window.navigator.userAgent),
        }
    };

    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps");
        //console.log(nextProps)
    };

    componentDidMount() {
        console.log("componentDidMount");

        MenuInitRun()
        var $ = window.$
        var $window = $(window);
        var PLUGIN_NAME = 'vide';
        // Window resize event listener
        $window.on('resize.' + PLUGIN_NAME, function () {
            for (var len = $[PLUGIN_NAME].lookup.length, i = 0, instance; i < len; i++) {
                instance = $[PLUGIN_NAME].lookup[i];

                if (instance && instance.settings.resizing) {
                    instance.resize();
                }
            }
        });

        // https://github.com/VodkaBears/Vide/issues/68
        $window.on('unload.' + PLUGIN_NAME, function () {
            return false;
        });

        if ($window.width() >= 768) {
            // Auto initialization
            // Add 'data-vide-bg' attribute with a path to the video without extension
            // Also you can pass options throw the 'data-vide-options' attribute
            // 'data-vide-options' must be like 'muted: false, volume: 0.5'
            $(document).find('[data-' + PLUGIN_NAME + '-bg]').each(function (i, element) {
                console.log(i, element)
                var $element = $(element);
                var options = $element.data(PLUGIN_NAME + '-options');
                var path = $element.data(PLUGIN_NAME + '-bg');

                $element[PLUGIN_NAME](path, options);
            });
        }

        $(".navigation .menu > ul > li").hover(function () {
            $(this).find('.navi_sub').show();
        }, function () {
            $(this).find('.navi_sub').hide();
        });

        $("#navigation .menu > li").hover(function () {
            $(this).find('.navi_sub').show();
        }, function () {
            $(this).find('.navi_sub').hide();
        });

        /*var user = localStorage.user?JSON.parse(localStorage.user):null
        if(!user || !user.user_id) {
            this.context.router.history.replace('/login');
            return
        }*/

        // pubsub
        pubsub.subscribe('refreshUserInfo', () => {
            var user = localStorage.user ? JSON.parse(localStorage.user) : null
            if (user && user.user_id) {
                apiService.getUserInfo((err, ret) => {
                    if (!err) {
                        console.log('updated')
                        this.setState({
                            user: ret
                        })
                    }
                })
            }
        })
    }

    logout(e) {
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }
        localStorage.token = null
        localStorage.user = null

        this.context.router.history.replace('/login');
    }

    changeLang(lang, e) {
        console.log(lang, e)
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }
        localStorage.lang = lang
        window.location.href = window.location.href
    }

    render() {
        var user = localStorage.user ? JSON.parse(localStorage.user) : null
        var isLogin = user && user.user_id
        var tokenVal = isLogin ? new BigNumber(user.token_amount_virtual).dividedBy(new BigNumber('1000000')).toNumber() : 0
        if (tokenVal != Math.floor(tokenVal)) {
            tokenVal = tokenVal.toFixed(3)
        }
        console.log('after tokenVal -> ', tokenVal);
        var lang = localStorage.lang || 'ko'
        return (
            <section className="home">
                <div id="header" className="ts">
                    <div className="row">
                        <div id="top_bar" className="top_bar ts">
                            {!isLogin && <ul className="top_menu">
                                <li><Link to={'/login'}>{utilService.getLangByCode('menu.login', '로그인')}</Link></li>
                            </ul>}
                            {isLogin && <ul className="top_menu">
                                <li><a href={'/logout'} onClick={this.logout.bind(this)}>{utilService.getLangByCode('menu.logout', '로그아웃')}</a></li>
                            </ul>}
                        </div>
                        <div className="navigation">
                            <div className="menu first">

                                <ul>
                                    <span>
                                        <Link to="/" className="menu_color"><i className="fas fa-home menu-text"></i>{utilService.getLangByCode('menu.home', 'HOME')}</Link>
                                    </span>

                                    <span>
                                        <Link to="/send" className="menu_color"><i className="far fa-paper-plane menu-text"></i>{utilService.getLangByCode('menu.sendmoney', '송금하기')}</Link>
                                    </span>

                                    <span>
                                        <Link to="/usage" className="menu_color"><i className="far fa-file-alt menu-text"></i>{utilService.getLangByCode('menu.usage', '사용내역')}</Link>
                                    </span>
                                    <span>
                                        <Link to="/deals" className="menu_color"><i className="far fa-file-powerpoint menu-text"></i>{utilService.getLangByCode('menu.deals', '거래내역')}</Link>
                                    </span>
                                    <li className="li_community ">
                                        <Link to="/notice" className="menu_color"><i className="fas fa-headset menu-text"></i>{utilService.getLangByCode('menu.support', '고객지원')}</Link>
                                        <div className="navi_sub">
                                            <ul>
                                                <li ><Link to="/notice">{utilService.getLangByCode('menu.notice', '공지사항')}</Link></li>
                                                <li ><Link to="/faq">{utilService.getLangByCode('menu.faq', 'FAQ')}</Link></li>
                                                <li ><Link to="/sendmail">{utilService.getLangByCode('menu.qna', '1:1 문의')}</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <Link to="/" id="m_logo"><img src="assets/images/logo_m_x2.png" alt="sey COIN LOGO" /></Link>
                        <a href="#" id="m_nav"><span className="line"></span></a>
                    </div>
                </div>

                <div id="m_menu" className="m_menu">
                    <div className="m_menu_wrap">
                        <div className="m_menu_header">
                            <div className="pull-left">
                                {!isLogin && <ul className="m_menu_top">
                                    <li><Link to={'/login'}>{utilService.getLangByCode('menu.login', '로그인')}</Link></li>
                                </ul>}
                                {isLogin && <ul className="m_menu_top">
                                    <li><a href={'/logout'} onClick={this.logout.bind(this)}>{utilService.getLangByCode('menu.logout', '로그아웃')}</a></li>
                                </ul>}
                            </div>

                            <div className="m_menu_close pull-right">
                                <a href="#" id="m_menu_close" className="m_close" title="Close" />
                            </div>
                        </div>

                        <div className="m_menu_con">
                        </div>
                    </div>
                </div>


                {(this.state.md.mobile() != 'iPhone') && <div id="block" className="mov_bg moinv" data-vide-bg="video/ocean">
                    {user && <a href="#home" className="mgt_m5 user"><span style={{ marginRight: 30 }}><i className="fa fa-user" style={{ marginRight: 5 }}></i>{user.user_id}</span><span>{tokenVal}</span><span>coin</span></a>}
                </div>}


                <div id="sub_visual" className="sub_visual_m pcinv">
                    {user && <a href="#home" className="mgt_m5 user"><span style={{ marginRight: 30 }}><i className="fa fa-user" style={{ marginRight: 5 }}></i>{user.user_id}</span><span>{tokenVal}</span><span>coin</span></a>}
                </div>

            </section>
        );
    };
};

Header.contextTypes = {
    router: PropTypes.object.isRequired
}

export default Header;
