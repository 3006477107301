import React, {Component} from 'react';

import Header from './Header'
import Footer from './Footer'
class Base extends Component {
    componentWillMount() {

    }

    render() {
        return (
            <div>
                {/*<div className="page-loader startLoad">
                    <div className="loader startLoad"></div>
                </div>*/}

                <div id="wrap">
                    <Header/>

                    { this.props.children }

                    <Footer />
                    <div id="mask"></div>
                </div>
            </div>
        );
    }

}

export default Base;
