import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pubsub from 'pubsub-js';

import apiService from "../../apis/apiService"
import utilService from "../../common/utilService"

class SendMailPage extends Component {

    componentDidMount() {
        document.documentElement.scrollTop = 0
        var user = localStorage.user ? JSON.parse(localStorage.user) : null
        if (!user || !user.user_id) {
            this.context.router.history.replace('/login');
            return
        }
        pubsub.publish('refreshUserInfo');
    };

    componentDidUpdate(prevProps, prevState) {

    }

    sendMail() {
        if (this.refs.email.value == '') {
            alert(utilService.getLangByCode('msg19', '이메일을 입력해주세요'))
            this.refs.email.focus()
            return;
        }

        if (!utilService.validateEmail(this.refs.email.value)) {
            alert(utilService.getLangByCode('msg57', '정확한 이메일을 입력해주세요'))
            this.refs.email.focus()
            return;
        }

        if (this.refs.category.value == '') {
            alert(utilService.getLangByCode('msg20', '카테고리를 선택해주세요'))
            this.refs.category.focus()
            return;
        }
        if (this.refs.content.value == '') {
            alert(utilService.getLangByCode('msg21', '내용을 입력해주세요'))
            this.refs.content.focus()
            return;
        }

        var user = localStorage.user ? JSON.parse(localStorage.user) : null

        apiService.sendMail({
            email: this.refs.email.value,
            category: this.refs.category.value,
            content: this.refs.content.value,
            user_id: user ? user.user_id : '',
            user_number: user ? user.user_number : ''
        }, (err, ret) => {
            console.log(ret);
            if (!err) {
                if (ret.result_code == 'ok') {
                    // alert(utilService.getLangByCode('msg55', "성공적으로 처리되었습니다."))
                    window.$("#success_modal").modal('show')
                    this.refs.content.value = ''
                    this.refs.email.value = ''
                    this.refs.category.value = ''
                    this.refs.email.focus()
                } else {
                    alert(utilService.getLangByCode('msg22', "처리에 실패하였습니다."))
                }
            } else {
                alert(utilService.getLangByCode('msg22', "처리에 실패하였습니다."))
            }
        })
    }

    goHome() {
        window.$("#success_modal").modal('hide')
        this.context.router.history.push('/');
    }

    render() {
        return (
            <div>
                <section className="contact padding-top-90 padding-bottom-90 primary_bg" id="contact">
                    <div className="row" style={{ maxWidth: 1220 }}>
                        <div className="col-md-12">
                            <div className="kv_csr" id="section_csr">
                                <div className="row">
                                    <h2 className="stt01"><i className="fa fa-quote-left" style={{ marginRight: 5 }}></i>{utilService.getLangByCode('label.qna', "1:1 문의")}</h2>
                                    <p className="stxt01">{utilService.getLangByCode('msg6', "궁금하신 사항에 빠른 답변 드리겠습니다")}</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="row">
                                </div>
                                <div className="notice_detail">
                                    <table className="table mofo13">
                                        <colgroup>
                                            <col width="20%" />
                                            <col width="80%" />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <td className="td_st1">{utilService.getLangByCode('label.email', "이메일")}</td>
                                                <td className="td_st4">
                                                    {/*<div style={{padding:8,verticalAlign:'middle'}}>
                                                    <span>김예스(yes24)</span><span className="mgl_10">님</span>
                                                </div>*/}
                                                    <div className="col-sm-12" style={{ padding: 8 }}>
                                                        <input type="email" ref="email" className="form-control4" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={utilService.getLangByCode('msg25', "답변받을 이메일주소 입력")} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            <tr>
                                                <td className="td_st1">{utilService.getLangByCode('label.category', "카테고리")}</td>
                                                <td className="td_st3">
                                                    <select className="custom-select w-sm" ref="category">
                                                        <option value="">{utilService.getLangByCode('label.type', "구분")}</option>
                                                        <option value="AA">{utilService.getLangByCode('label.loginaccount', "로그인/계정")}</option>
                                                        <option value="BB">{utilService.getLangByCode('label.token', "코인")}</option>
                                                        <option value="CC">{utilService.getLangByCode('label.membership', "멤버십")}</option>
                                                        <option value="DD">{utilService.getLangByCode('label.wallet', "전자지갑")}</option>
                                                        <option value="EE">{utilService.getLangByCode('label.other', "기타")}</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td_st1 bdl">{utilService.getLangByCode('label.content', "내 용")}</td>
                                                <td className="td_st3">
                                                    <textarea ref="content" className="form-control" style={{ height: 250 }} id="exampleTextarea" rows="6"></textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <footer className="table_footer_background2">
                                <div className="row">
                                    <center>
                                        <button type="button" className="btn btn-primary wd100" onClick={this.sendMail.bind(this)}>{utilService.getLangByCode('label.send', "보내기")}</button>
                                    </center>
                                </div>
                            </footer>
                        </div>
                    </div>
                    <div className="modal fade" id="success_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{ top: '25%' }}>
                        <div className="modal-dialog modal-sm" style={{ width: '320px' }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal">
                                        <span aria-hidden="true">×</span>
                                        <span className="sr-only">Close</span>
                                    </button>
                                    <h3 className="modal-title">감사합니다.</h3>
                                </div>
                                <div className="modal-body" style={{ fontSize: '14px' }}>
                                    고객님의 문의가 정상적으로 접수되었습니다.
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={this.goHome.bind(this)}>홈으로 이동</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };
};

SendMailPage.contextTypes = {
    router: PropTypes.object.isRequired
}
export default SendMailPage;
