import React, { Component } from 'react';
import { Pagination } from 'react-bootstrap'
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'
import pubsub from 'pubsub-js';

import apiService from "../../apis/apiService"
import utilService from "../../common/utilService";

class DealsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            transfers: [],
            activePage: 1,
            numOfPages: 1,
            totalNumberOfRecords: 0,
            user: localStorage.user ? JSON.parse(localStorage.user) : null,
            startDate: moment(),
            toDate: moment(),
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0
        var user = localStorage.user ? JSON.parse(localStorage.user) : null
        if (!user || !user.user_id) {
            this.context.router.history.replace('/login');
            return
        }
        this.searchPage(1)
        pubsub.publish('refreshUserInfo');
    };

    searchPage(page) {
        console.log(page)
        var { startDate, toDate } = this.state
        console.log(startDate.format('YYYY-MM-DD') + ' 00:00:00')
        console.log(toDate.format('YYYY-MM-DD') + ' 00:00:00')
        apiService.getTransfers(startDate.format('YYYY-MM-DD') + ' 00:00:00', toDate.format('YYYY-MM-DD') + ' 23:59:59', page, 5, (err, rets) => {
            console.log('getTransfers', err, rets)
            if (!err) {
                this.setState({
                    activePage: page,
                    transfers: rets.rows,
                    numOfPages: Math.ceil(rets.count / 5),
                    totalNumberOfRecords: rets.count
                })
            }
        })
    }

    handlePageSelect(eventKey) {
        this.searchPage(eventKey)
    }

    changeStartDate(startDate) {
        this.setState({
            startDate
        })
    }

    changeEndDate(toDate) {
        this.setState({
            toDate
        })
    }

    setPeriod(days) {
        var startDate = moment().add(days * (-1), 'days');
        var toDate = moment()
        this.setState({
            startDate,
            toDate
        })
    }


    render() {
        var { transfers, activePage, numOfPages, totalNumberOfRecords, user, startDate, toDate } = this.state
        return (
            <div>
                <section className="contact padding-top-90 padding-bottom-90 primary_bg" id="contact">
                    <div className="row" style={{ maxWidth: 1220 }}>
                        <div className="col-md-12">
                            <div className="kv_csr" id="section_csr">
                                <div className="row">
                                    <h2 className="stt01"><i className="fa fa-quote-left" style={{ marginRight: 5 }}></i>{utilService.getLangByCode('label.transactionhistory', '거래내역')}</h2>
                                    <p className="stxt01">{utilService.getLangByCode('msg12', '코인 거래 내역을 기간별로 조회할 수 있습니다.')}</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="p-2">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="input-group date fl">
                                                <DatePicker selected={startDate} maxDate={toDate} onChange={this.changeStartDate.bind(this)} className='form-control2 fl' />
                                            </div>
                                            <span className="fl" style={{ marginLeft: 5, marginRight: 5 }}>~</span>
                                            <div className="input-group date fl">
                                                <DatePicker selected={toDate} minDate={startDate} onChange={this.changeEndDate.bind(this)} className='form-control2 fl' />
                                            </div>
                                            <div className="modiv">
                                                <button type="button" className="btn btn-primary ml10" onClick={this.searchPage.bind(this, 1)}>{utilService.getLangByCode('label.inquiry', '조회')}</button>
                                                <button type="button" className="btn btn-light ml5" onClick={this.setPeriod.bind(this, 7)}>{utilService.getLangByCode('label.7days', '7일')}</button>
                                                <button type="button" className="btn btn-light ml5" onClick={this.setPeriod.bind(this, 30)}>{utilService.getLangByCode('label.30days', '30일')}</button>
                                                <button type="button" className="btn btn-light ml5" onClick={this.setPeriod.bind(this, 90)}>{utilService.getLangByCode('label.90days', '90일')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="usage_table">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <colgroup>
                                                <col width="20%" />
                                                <col width="25%" />
                                                <col width="25%" />
                                                <col width="15%" />
                                                <col width="15%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>{utilService.getLangByCode('label.transactiondate', '거래일시')}</th>
                                                    <th>{utilService.getLangByCode('label.senderId', '발신 ID(주소)')}</th>
                                                    <th>{utilService.getLangByCode('label.receiverId', '수신 ID(주소)')}</th>
                                                    <th>{utilService.getLangByCode('label.receive', '입금')}</th>
                                                    <th>{utilService.getLangByCode('label.sending', '출금')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    transfers.map((item, index) => {
                                                        var isDeposit = (item.de_or_wd === 'deposit' || item.de_or_wd === 'sendTokenToUser')
                                                        var isWithdraw = (item.de_or_wd === 'withdraw' || item.de_or_wd === 'collectTokenFromUser')
                                                        var fromId = item.from_id, toId = item.to_id, token = item.trans_token_count
                                                        var numL = token.length

                                                        console.log('token -> ', token);
                                                        //token = parseFloat(token.substring(0, numL - 15)) / 1000
                                                        token = token / 1000000;
                                                        console.log('parsing token -> ', token);

                                                        if (isDeposit && toId === user.user_id) {
                                                            if (item.from_id === 'admin#') {
                                                                fromId = 'yes24'
                                                            } else if (fromId !== '') {
                                                            } else {
                                                                fromId = item.transaction_from_addr
                                                            }
                                                            toId = user.user_id


                                                            return <tr key={index}>
                                                                <td>{utilService.getDateTime2ByFormat(item.updated_at, 'YYYY-MM-DD HH:mm')}</td>
                                                                <td>{fromId}</td>
                                                                <td>{toId}</td>
                                                                <td><span>{isDeposit ? (token || 0) + ' coin' : ''}</span></td>
                                                                <td><span>{!isDeposit ? (token || 0) + ' coin' : ''}</span></td>
                                                            </tr>
                                                        } else if (isWithdraw && fromId === user.user_id) {
                                                            fromId = user.user_id
                                                            if (item.to_id === 'admin#') {
                                                                toId = 'yes24'
                                                            } else if (toId !== null) {
                                                            } else {
                                                                toId = item.transaction_to_addr
                                                            }



                                                            return <tr key={index}>
                                                                <td>{utilService.getDateTime2ByFormat(item.updated_at, 'YYYY-MM-DD HH:mm')}</td>
                                                                <td>{fromId}</td>
                                                                <td>{toId}</td>
                                                                <td><span thousandSeparator={true}>{isDeposit ? (token.toLocaleString() || 0) + ' coin' : ''}</span></td>
                                                                <td><span thousandSeparator={true}>{!isDeposit ? (token.toLocaleString() || 0) + ' coin' : ''}</span></td>
                                                            </tr>
                                                        } else {

                                                        }
                                                    })
                                                }
                                                {
                                                    transfers.length === 0 && <tr>
                                                        <td colSpan={5} className="text-center">
                                                            {utilService.getLangByCode('msg50', '거래내역이 없습니다.')}
                                                        </td>
                                                    </tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <footer className="table_footer_background bdt">
                                    <div className="row">
                                        <div className="col-sm-4">
                                        </div>
                                        <div className="col-sm-4" style={{ textAlign: 'center' }}>
                                            <Pagination
                                                className={totalNumberOfRecords === 0 ? 'hidden' : 'shown'}
                                                prev
                                                next
                                                first
                                                last
                                                ellipsis
                                                maxButtons={5}
                                                items={numOfPages}
                                                activePage={activePage}
                                                onSelect={this.handlePageSelect.bind(this)}>
                                            </Pagination>
                                        </div>
                                        <div className="col-sm-4">
                                        </div>
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        );
    };
};
DealsPage.contextTypes = {
    router: PropTypes.object.isRequired
}

export default DealsPage;
