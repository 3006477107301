import React, { Component } from 'react';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import MobileDetect from 'mobile-detect'
import apiService from "../../apis/apiService"
import utilService from "../../common/utilService";

class LoginPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            md: new MobileDetect(window.navigator.userAgent),
        }
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0
    };

    componentDidUpdate(prevProps, prevState) {
    }
    submit(e) {
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }

        console.log('this.refs.userId', this.refs.userId)
        apiService.login(this.refs.userId.value, this.refs.userPasword.value, (err, ret) => {
            if (!err) {
                this.context.router.history.replace('/');
            } else {
                console.log(err, ret);
                if (err.code) {
                    switch (err.code) {
                        case 410: //인증써버실패
                            alert(utilService.getLangByCode('msg70', err.message));
                            break;
                        case 411: //재시도 오류경우
                            alert(utilService.sprintf(utilService.getLangByCode('msg71', "%d분후 다시 시도해주세요."), err.offset));
                            break;
                    }
                } else {
                    alert(utilService.getLangByCode('msg51', '등록되지 않은 아이디거나, 아이디 또는 비밀번호를 잘못 입력하셨습니다'))
                }
            }
        })
    }
    render() {
        return (
            <div>
                <div id="sub_menu" className="sub_menu">
                    <div className="row">
                        <ul className="clearfix col3">
                            <li className='act'><Link to={'/login'}>{utilService.getLangByCode('label.login', '로그인')}</Link></li>
                            <li ><Link to={'/policy'}>{utilService.getLangByCode('label.terms', '이용약관')}</Link></li>
                            <li ><Link to={'/privacy'}>{utilService.getLangByCode('label.privacy', '개인정보처리방침')}</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="login pt70 pb90">
                    <div className="row pb30">
                        <div className="login_box pt50 pb30">
                            <form method="post" acceptCharset="utf-8" onSubmit={this.submit.bind(this)}>
                                <div className="input_box">
                                    <input type="text" name="user_id" ref="userId" placeholder={utilService.getLangByCode('label.userid', '아이디')} />
                                    <input type="password" name="user_pw" ref="userPasword" placeholder={utilService.getLangByCode('label.password', '비밀번호')} />
                                </div>
                                <div className="submit_box">
                                    <input type="submit" value={utilService.getLangByCode('menu.login', '로그인')} />
                                </div>
                            </form>
                            <p className="login_txt text-center"></p>
                        </div>

                        <p className="login_txt">- {utilService.getLangByCode('label.createAccount', '아직 회원이 아니신가요?')}<a href={this.state.md.mobile() ? "https://m.yes24.com/Momo/Member/FTMemAcc.aspx" : "https://www.yes24.com/Member/FTMemAcc.aspx"} className="btn" target="_blank">{utilService.getLangByCode('label.loginWithYES24', '예스24 회원가입')}</a></p>
                        <p className="login_txt">- {utilService.getLangByCode('label.forgot', '아이디, 비밀번호를 잊어버리셨나요?')}<a href={this.state.md.mobile() ? "https://m.yes24.com/Momo/Templates/FTPopupForgetID.aspx" : "https://www.yes24.com/Templates/FTLogin.aspx"} target="_blank" className="btn">{utilService.getLangByCode('label.findWithYES24', '예스24 ID/PW 찾기')}</a></p>
                        <p className="login_txt text-center" style={{ maxWidth: '595px', fontSize: '12px' }} >{utilService.getLangByCode('label.suggest', '신규 회원 가입 및 아이디 찾기 / 비밀번호 찾기 이용은 YES24 홈페이지로 이동하여 진행됩니다.')}</p>
                    </div>
                </div>
            </div>
        );
    };
};


LoginPage.contextTypes = {
    router: PropTypes.object.isRequired
}

export default LoginPage;
