import React, { Component } from 'react';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import pubsub from 'pubsub-js';

import * as qs from 'query-string';
import apiService from "../../apis/apiService"
import utilService from "../../common/utilService";

class NoticeDetailPage extends Component {
    constructor(props) {
        super(props)
        this.seq = qs.parse(this.props.location.search).seq
        this.activePage = qs.parse(this.props.location.search).activePage || 1

        this.state = {
            notice: {},
            next: null,
            before: null
        }
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        this.goPage(this.seq)

        var user = localStorage.user ? JSON.parse(localStorage.user) : null
        if (user && user.user_id) {
            pubsub.publish('refreshUserInfo');
        }
    };

    componentDidUpdate(prevProps, prevState) {
    }

    goList() {
        this.context.router.history.push('/notice?activePage=' + this.activePage);
    }

    goPage(seq) {
        apiService.getNotice(seq, (err, ret) => {
            console.log('getNotice', err, ret)
            if (!err) {
                this.setState({
                    notice: ret.data,
                    next: ret.next,
                    before: ret.before
                })
            }
        })
    }
    render() {
        var { notice, next, before } = this.state
        var lang = utilService.getCurrentLang()

        const replace = ((t) => {
            var t1 = String(t)
            t1 = t1.replace(/<p/g, '<div');
            t1 = t1.replace(/p>/g, 'div>');
            t1 = t1.replace(/<div><\/div>/g, '<br>');
            return t1
        })

        return (
            <div>
                <section className="contact padding-top-90 padding-bottom-90 primary_bg" id="contact">


                    <div className="row" style={{ maxWidth: 1220 }}>
                        <div className="col-md-12">


                            <div className="kv_csr" id="section_csr">
                                <div className="row">
                                    <h2 className="stt01"><i className="fa fa-quote-left" style={{ marginRight: 5 }}></i>{utilService.getLangByCode('label.notice', '공지사항')}</h2>
                                    <p className="stxt01">{utilService.getLangByCode('msg1', 'sey코인 관련 소식을 전해 드립니다.')}</p>
                                </div>
                            </div>



                            <div className="box">


                                <div className="row">


                                </div>



                                <div className="notice_detail">
                                    <table className="table">

                                        <colgroup>
                                            <col width="20%" />
                                            <col width="80%" />
                                        </colgroup>

                                        <thead>
                                            <tr>
                                                <td className="td_st1">{utilService.getLangByCode('label.title', '제　목')}</td>
                                                <td className="td_st3">{lang === 'ko' ? notice.subject : notice.subject_en}</td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td className="td_st1">{utilService.getLangByCode('label.regdate', '등록일')}</td>
                                                <td className="td_st3">{utilService.getDate(notice.reg_dt)}</td>
                                            </tr>
                                            <tr>
                                                <td className="td_st2 bdl">{utilService.getLangByCode('label.content', '내　용')}</td>
                                                <td className="td_st3">
                                                    <div dangerouslySetInnerHTML={{ __html: (lang === 'ko' ? replace(notice.note) : replace(notice.note_en)) }} />
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <footer className="table_footer_background">
                                <div className="row">
                                    <center>
                                        <button type="button" className="btn btn-primary wd100" onClick={this.goList.bind(this)}>{utilService.getLangByCode('label.list', '목록')}</button>
                                    </center>
                                </div>
                            </footer>


                            <div className="box mgt_30">
                                <div className="notice_detail">
                                    <table className="table">

                                        <colgroup>
                                            <col width="20%" />
                                            <col width="80%" />
                                        </colgroup>

                                        <thead>
                                            <tr>
                                                <td className="td_st1">{utilService.getLangByCode('label.previous', '이전 글')}</td>
                                                {before && <td className="td_st3"><Link to={'/noticeDetail?seq=' + before.seq} onClick={this.goPage.bind(this, before.seq)}>{lang === 'ko' ? before.subject : before.subject_en}</Link></td>}
                                                {!before && <td className="td_st3">{utilService.getLangByCode('msg53', '이전 글이 없습니다.')}</td>}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td className="td_st1">{utilService.getLangByCode('label.next', '다음 글')}</td>
                                                {next && <td className="td_st3"><Link to={'/noticeDetail?seq=' + next.seq} onClick={this.goPage.bind(this, next.seq)}>{lang === 'ko' ? next.subject : next.subject_en}</Link></td>}
                                                {!next && <td className="td_st3">{utilService.getLangByCode('msg54', '다음 글이 없습니다.')}</td>}
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        );
    };
};
NoticeDetailPage.contextTypes = {
    router: PropTypes.object.isRequired
}
export default NoticeDetailPage;
