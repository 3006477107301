import React, { Component } from 'react';
import apiService from "../../apis/apiService"
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import utilService from "../../common/utilService";

class WalletPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            user:localStorage.user?JSON.parse(localStorage.user):null
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0
        var user = localStorage.user?JSON.parse(localStorage.user):null
        if(!user || !user.user_id) {
            this.context.router.history.replace('/login');
            return
        }
    };

    componentDidUpdate(prevProps, prevState) {
    }

    createWalletAddress(e){
        if(e) {
            e.stopPropagation()
            e.preventDefault()
        }

        var {user} = this.state
        if(user.user_wallet_addr) {
            alert(utilService.getLangByCode('msg16', '이미 생성되었습니다.'))
            return
        }

        apiService.createWalletAddress((err, ret)=>{
            if(!err) {
                user.user_wallet_addr = ret.address
                localStorage.user = JSON.stringify(this.state.user)
                this.setState({
                    user
                })
            }
        })
    }

    render() {
        var {user} = this.state
        var isLogin = user && user.user_id
        return (
            <div>
                <section className="contact padding-top-90 padding-bottom-90 primary_bg" id="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1">
                                <div className="kv_csr" id="section_csr">
                                    <div className="row">
                                        <h2 className="stt01"><i className="fa fa-quote-left" style={{marginRight:5}}></i>{utilService.getLangByCode('label.mywallet', '나의 지갑')}</h2>
                                        <p className="stxt01">{utilService.getLangByCode('msg17', '개인지갑 주소 및 QRCODE를 생성합니다.')}</p>
                                    </div>
                                </div>
                                <div className="contact_form">
                                    <form method='post'><input type='hidden' name='form-name' value='form 1' />
                                        <button className="btn mgb_10" onClick={this.createWalletAddress.bind(this)}>{utilService.getLangByCode('label.createwallet', '개인지갑 생성')}</button>
                                        <div className="input-group mgb_10 wi_30">
                                            <span className="input-group-addon wi_15">{utilService.getLangByCode('label.myaddress', '내주소')}</span>
                                            <input id="myaddress" type="text" className="form-control" name="myaddress" placeholder={utilService.getLangByCode('label.autogenerate', '자동생성')} readOnly={true} value={user?user.user_wallet_addr||'':''}/>
                                        </div>
                                        {user&&user.user_wallet_addr&&<div className="input-group mgb_30 wi_30">
                                            <span className="input-group-addon wi_15">QRCODE</span>
                                            <QRCode value={user.user_wallet_addr}/>
                                        </div>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };
};
WalletPage.contextTypes = {
    router: PropTypes.object.isRequired
}
export default WalletPage;
