import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import BigNumber from 'bignumber.js';
import pubsub from 'pubsub-js';
import PropTypes from 'prop-types';

import apiService from "../../apis/apiService"
import utilService from "../../common/utilService"

class SendPage extends Component {
    constructor(props) {
        super(props)
        var user = localStorage.user ? JSON.parse(localStorage.user) : null;
        this.state = {
            user: user,
            userAmount: user ? new BigNumber(user.token_amount_virtual).dividedBy(new BigNumber('1000000')).toNumber() : 0,
            tokenAmount: '0.000',
            token: 0,
            retreiveMsg: utilService.getLangByCode('message65', '받으실 분의 ID를 조회해주세요'),

            toUserid: "",
            toUsernumber: "",
            toUsername: "",
            enabled: false
        }
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0
        var user = localStorage.user ? JSON.parse(localStorage.user) : null
        if (!user || !user.user_id) {
            this.context.router.history.replace('/login');
            return
        }
        pubsub.publish('refreshUserInfo');
    };

    componentDidUpdate(prevProps, prevState) {
    }

    changeAmount(val, e) {
        if (e) {
            e.stopPropagation()
        }

        var { tokenAmount, token } = this.state
        var tokenAmount = val.value
        var addedVal = new BigNumber(val.value).times(new BigNumber('1000000'))
        token = addedVal.toFixed(0)
        // console.log(val.value, token, addedVal)
        this.setState({
            tokenAmount,
            token
        })
    }

    changeToUserid(e) {
        if (e) {
            e.stopPropagation()
        }

        var { toUserid } = this.state
        var toUserid = e.target.value
        // console.log(toUserid)
        this.setState({
            toUserid
        })
    }

    send(e) {
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }

        if (!this.state.enabled) {
            alert(utilService.getLangByCode('msg65', "받으실 분의 ID를 조회해주세요"))
            return
        }

        var tokenAmount = Number(this.refs.tokenAmount.state.numAsString)
        if (tokenAmount < 0.001) {
            alert(utilService.getLangByCode('msg72', "보낼 코인수를 바로 입력해주세요"))
            return
        }

        var ret = window.confirm(utilService.getLangByCode('msg23', '전송 후에는 취소가 불가능 합니다. 송금하시겠습니까?'))
        if (ret) {
            apiService.sendToken({
                tokenAmount: tokenAmount,
                toUserid: this.state.toUserid,
                toUsernumber: this.state.toUsernumber
            }, (err, ret) => {
                if (!err) {
                    if (ret.result == 'ok') {
                        // 동기화
                        pubsub.publish('refreshUserInfo');
                        var userAmount = this.state.userAmount - this.state.tokenAmount;
                        this.setState({ userAmount });

                        alert(utilService.getLangByCode('msg58', "정확히 전송되었습니다."))
                    } else {
                        switch (ret.result_code) {
                            case 200:
                                alert(utilService.getLangByCode('msg59', "유저아이디가 유효하지 않습니다."))
                                break
                            case 300:
                                alert(utilService.getLangByCode('msg60', "코인수량을 입력하세요."))
                                break
                            case 400:
                                alert(utilService.getLangByCode('msg61', "코인수량이 없습니다."))
                                break
                            case 500:
                                alert(utilService.getLangByCode('msg62', "서버 코인 잔액이 부족합니다"))
                                break
                            case 600:
                                alert(utilService.getLangByCode('msg63', "등록되지 않은 지갑 주소"))
                                break
                        }
                    }
                } else {
                    alert(utilService.getLangByCode('msg22', "처리에 실패하였습니다."))
                }

                this.setState({
                    tokenAmount: '0.000',
                    toUserid: "",
                    toUsername: "",
                    toUsernumber: "",
                    enabled: false,
                    retreiveMsg: utilService.getLangByCode('message65', '받으실 분의 ID를 조회해주세요'),
                })
                this.refs.toUserid.value = ''
            })
        }
    }

    cancelSend(e) {
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }

        this.setState({
            tokenAmount: '0.000',
            toUserid: ""
        })
        this.refs.toUserid.value = ''
    }

    retreiveToUserid(e) {
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }

        var { user, toUserid, tokenAmount } = this.state
        console.log("toUserid:", toUserid)
        if (tokenAmount < 0.001) {
            alert(utilService.getLangByCode('msg72', "보낼 코인수를 바로 입력해주세요."))
            return
        }

        if (toUserid.length == 0) {
            alert(utilService.getLangByCode('msg64', "받으실 분의 ID를 입력해주세요"))
            return
        }

        if (toUserid.toUpperCase() == user.user_id.toUpperCase()) {
            alert(utilService.getLangByCode('msg66', "본인 아이디 입니다."))
            return
        }

        // 수신자 조회
        apiService.checkReceiverId(toUserid, (err, res) => {
            console.log(err, res)
            if (err) {
                if (err.message) {
                    alert(err.message)
                } else {
                    alert(utilService.getLangByCode('msg67', "존재하지 않는 아이디입니다. 다시 확인해주세요"))
                }
                this.setState({
                    enabled: false,
                    toUserid: '',
                    toUsernumber: '',
                    toUsername: ''
                })
                return
            }

            if (res && res.success) {
                this.setState({
                    enabled: true,
                    toUserid: res.userid,
                    toUsernumber: res.userno,
                    toUsername: res.usernm,
                    retreiveMsg: utilService.sprintf(utilService.getLangByCode('label.sendcoin', "%s에게 %d 코인을 송금합니다."), utilService.getUserName(res.usernm), this.state.tokenAmount)
                })
            }
        })
    }

    render() {
        var { user, userAmount, tokenAmount, toUserid } = this.state
        if (!user) user = {}
        return (
            <div>
                <section className="contact padding-top-90 padding-bottom-90 primary_bg" id="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1">
                                <div className="kv_csr" id="section_csr">
                                    <div className="row">
                                        <h2 className="stt01"><i className="fa fa-quote-left" style={{ marginRight: 5 }}></i>{utilService.getLangByCode('label.doremittance', '송금하기')}</h2>
                                        <p className="stxt01"></p>
                                    </div>
                                </div>
                                <div className="contact_form">
                                    <form method='post'>
                                        <input type='hidden' name='form-name' value='form 1' />
                                        <div className="input-group mgb_10 wi_30">
                                            <span className="input-group-addon wi_15">{utilService.getLangByCode('label.sendaccount', '출금계정')}</span>
                                            <input id="myaddress" type="text" className="form-control" name="myaddress" value={user.user_id} readOnly />
                                        </div>

                                        <div className="input-group mgb_10 wi_30">
                                            <span className="input-group-addon wi_15">{utilService.getLangByCode('label.mycoin', '나의 코인')}</span>
                                            <NumberFormat value={userAmount} className="form-control" thousandSeparator={true} decimalScale={3} fixedDecimalScale={true} readOnly />
                                        </div>

                                        <div className="input-group mgb_10 wi_30">
                                            <span className="input-group-addon wi_15">{utilService.getLangByCode('label.sendtoken', '송금할 코인수')}</span>
                                            <NumberFormat value={tokenAmount} ref="tokenAmount" className="form-control" thousandSeparator={true} onValueChange={this.changeAmount.bind(this)} placeholder={utilService.getLangByCode('label.inputsendamount', '송금할 코인수 입력')} style={{ lineHeight: '30px' }} />
                                        </div>

                                        <div className="input-group mgb_10 wi_30">
                                            <span className="input-group-addon wi_15">{utilService.getLangByCode('label.receiveraddress', '받는 분 예스24 ID')}</span>
                                            <input type="text" value={toUserid || ''} className="form-control fs13" ref="toUserid" onChange={this.changeToUserid.bind(this)} placeholder={utilService.getLangByCode('label.inputreceiveaddress', '받는 분 예스24 ID 입력')} />
                                            <div className="input-group-btn">
                                                <button className="btn btn_sm" onClick={this.retreiveToUserid.bind(this)}>{utilService.getLangByCode('label.inquiry', '조회')}</button>
                                            </div>
                                        </div>
                                        <div className="stxt01">{this.state.retreiveMsg}</div>
                                        <center>
                                            <button className="btn mgb_10" onClick={this.send.bind(this)}>{utilService.getLangByCode('label.doremittance', '송금하기')}</button>
                                            {/*<button className="btn mgb_10 mgl_30" onClick={this.cancelSend.bind(this)}>취소</button>*/}
                                        </center>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };
};

SendPage.contextTypes = {
    router: PropTypes.object.isRequired
}
export default SendPage;
