import async  from 'async'
import {en} from '../locales/en/en.json'
import {ko} from '../locales/ko/ko.json'
var $ = window.$
class Cache {
    constructor() {
        this.lang = {
            'en': en,
            'ko': ko,
        }
        this.codeMap = []

        console.log(this.lang)
    }

    getLang(lang, code) {
        if (!code)
            return "";

        var langPack = this.lang[lang];
        return langPack[code]
    }
}

export default (new Cache);