import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';

class RouterLayoutOptional extends Component {

    constructor(props, context) {
        super(props, context);
    }

    isLayout(layout){
        return typeof(layout) === 'function' && !!layout.prototype.isReactComponent;
    }

    render() {

        let { component: Component, layout: Layout, authGuard = true, ...rest } = this.props;
        
        if (!Component) {
            return ("Component Undefined");
        }

        return (<Route {...rest} render={matchProps => (this.isLayout(Layout) ? <Layout><Component {...matchProps} /></Layout> : <Component {...matchProps} />)} />);
    }
}

export default
withRouter(RouterLayoutOptional);