import React, { Component } from 'react';
import { Pagination } from 'react-bootstrap'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import pubsub from 'pubsub-js';

import apiService from "../../apis/apiService"
import utilService from "../../common/utilService"
import * as qs from 'query-string';

class FaqPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            faqs: [],
            activePage: Number(qs.parse(this.props.location.search).activePage || 1),
            numOfPages: 1,
            totalNumberOfRecords: 0
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0
        this.searchPage(this.state.activePage)

        var user = localStorage.user ? JSON.parse(localStorage.user) : null
        if (user && user.user_id) {
            pubsub.publish('refreshUserInfo');
        }
    };

    searchPage(page) {
        apiService.getFaqs(this.refs.faqType.value, this.refs.query.value, page, 5, (err, rets) => {
            console.log('getFaqs', err, rets)
            if (!err) {
                this.setState({
                    activePage: page,
                    faqs: rets.rows,
                    numOfPages: Math.ceil(rets.count / 5),
                    totalNumberOfRecords: rets.count
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
    }

    handlePageSelect(eventKey) {
        this.searchPage(eventKey)
    }

    goDetail(item) {
        this.context.router.history.push('/faqDetail?seq=' + item.seq + '&activePage=' + this.state.activePage);
    }

    render() {
        var { faqs, activePage, numOfPages, totalNumberOfRecords } = this.state
        var lang = utilService.getCurrentLang()
        return (
            <div>
                <section className="contact padding-top-90 padding-bottom-90 primary_bg" id="contact">


                    <div className="row" style={{ maxWidth: 1220 }}>
                        <div className="col-md-12">
                            <div className="kv_csr" id="section_csr">
                                <div className="row">
                                    <h2 className="stt01"><i className="fa fa-quote-left" style={{ marginRight: 5 }}></i>FAQ</h2>
                                    <p className="stxt01">{utilService.getLangByCode('msg9', '자주하는 질문/답변을 확인해 보세요')}</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="p-2">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="fr">
                                                <button type="button" className="btn btn-primary" onClick={this.searchPage.bind(this, 1)}>{utilService.getLangByCode('label.search', '검색')}</button>
                                            </div>

                                            <div className="input-group fr">
                                                <input type="text" className="form-control3" ref="query" />
                                            </div>
                                            <div className="fr mgr_10">
                                                <select className="custom-select w-sm" ref="faqType">
                                                    <option value="">{utilService.getLangByCode('label.type', '구분')}</option>
                                                    <option value="AA">{utilService.getLangByCode('label.loginaccount', '로그인/계정')}</option>
                                                    <option value="BB">{utilService.getLangByCode('label.token', '코인')}</option>
                                                    <option value="CC">{utilService.getLangByCode('label.membership', '멤버십')}</option>
                                                    <option value="DD">{utilService.getLangByCode('label.wallet', '전자지갑')}</option>
                                                    <option value="EE">{utilService.getLangByCode('label.other', '기타')}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="usage_table">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <colgroup>
                                                <col width="10%" />
                                                <col width="15%" />
                                                <col width="75%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>{utilService.getLangByCode('label.number', '번호')}</th>
                                                    <th>{utilService.getLangByCode('label.type', '구분')}</th>
                                                    <th>{utilService.getLangByCode('label.title', '제목')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    faqs.map((item, index) => {
                                                        return <tr style={{ cursor: 'pointer' }} key={index} onClick={this.goDetail.bind(this, item)}>
                                                            <td>{(activePage - 1) * 5 + index + 1}</td>
                                                            <td>{utilService.getFaqType(item.faq_type)}</td>
                                                            <td style={{ textAlign: 'left' }}>{lang == 'ko' ? item.quest_note : item.quest_note_en}</td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <footer className="table_footer_background bdt">
                                    <div className="row">
                                        <div className="col-sm-4">
                                        </div>
                                        <div className="col-sm-4" style={{ textAlign: 'center' }}>
                                            <Pagination
                                                className={totalNumberOfRecords === 0 ? 'hidden' : 'shown'}
                                                prev
                                                next
                                                first
                                                last
                                                ellipsis
                                                maxButtons={5}
                                                items={numOfPages}
                                                activePage={activePage}
                                                onSelect={this.handlePageSelect.bind(this)}>
                                            </Pagination>
                                        </div>
                                        <div className="col-sm-4">
                                        </div>
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };
};
FaqPage.contextTypes = {
    router: PropTypes.object.isRequired
}
export default FaqPage;
