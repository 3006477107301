import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteLO from './components/routes/routeLayoutOptional';


import Base from './components/layout/Base';
import BaseHome from './components/layout/BaseHome';
import BaseLogin from './components/layout/BaseLogin';

import DealsPage from './components/page/DealsPage';
import FaqPage from './components/page/FaqPage';
import FaqDetailPage from './components/page/FaqDetailPage';
import HomePage from './components/page/HomePage';
import LoginPage from './components/page/LoginPage';
import NoticePage from './components/page/NoticePage';
import NoticeDetailPage from './components/page/NoticeDetailPage';
import PolicyPage from './components/page/PolicyPage';
import PrivacyPage from './components/page/PrivacyPage';
import SendMailPage from './components/page/SendMailPage';
import SendPage from './components/page/SendPage';
import UsagePage from './components/page/UsagePage';
import WalletPage from './components/page/WalletPage';
import AppPage from './components/page/AppPage';
import Error404 from './components/error/Error404';
import BaseDetail from './components/layout/BaseDetail';
import Maintenance from './components/error/Maintenance';

import UtilService from './common/utilService'

export default function configRoutes() {
  return (
    <Route>
      <div>
        <Switch>
          {UtilService.isMaintenance() ? <RouteLO exact path="/" component={Maintenance} /> : <RouteLO exact path='/' layout={BaseHome} component={HomePage} />}
          <RouteLO exact path='/privacy' layout={BaseLogin} component={PrivacyPage} />
          <RouteLO exact path='/policy' layout={BaseLogin} component={PolicyPage} />
          <RouteLO exact path='/deals' layout={Base} component={DealsPage} />
          <RouteLO exact path='/faq' layout={BaseDetail} component={FaqPage} />
          <RouteLO exact path='/faqDetail' layout={BaseDetail} component={FaqDetailPage} />
          <RouteLO exact path='/notice' layout={BaseDetail} component={NoticePage} />
          <RouteLO exact path='/noticeDetail' layout={BaseDetail} component={NoticeDetailPage} />
          <RouteLO exact path='/login' layout={BaseLogin} component={LoginPage} />
          <RouteLO exact path='/sendMail' layout={BaseDetail} component={SendMailPage} />
          <RouteLO exact path='/send' layout={Base} component={SendPage} />
          <RouteLO exact path='/usage' layout={Base} component={UsagePage} />
          <RouteLO exact path='/wallet' layout={Base} component={WalletPage} />
          <RouteLO exact path='/app' component={AppPage} />
          <RouteLO exact path="/*" component={Error404} />
        </Switch>
      </div>
    </Route>
  );
};
