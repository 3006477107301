import React, {Component} from 'react';

import HomeHeader from './HomeHeader'
import Footer from './Footer'
class BaseHome extends Component {

    componentWillMount() {

    }

    render() {
        return (
            <div>

                {/*<div className="page-loader startLoad">
                    <div className="loader startLoad"></div>
                </div>*/}

                <div id="wrap">
                    <HomeHeader/>

                    { this.props.children }

                    <Footer />
                    <div id="mask"></div>
                </div>
            </div>
        );
    }

}

export default BaseHome