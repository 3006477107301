import React, { Component } from 'react';
import MobileDetect from'mobile-detect'

export class AppPage extends Component {
  
componentWillMount(){
        this.state = {
                 md:new MobileDetect(window.navigator.userAgent),
        }
        console.log('### ### ### ### ###')
        console.log(this.state.md.mobile());
        console.log(this.state.md.userAgent());
        if(this.state.md.mobile()=='iPad' || this.state.md.mobile() =='iPhone'){
                window.location.href='https://itunes.apple.com/kr/app/%EC%84%B8%EC%9D%B4%EC%9B%94%EB%A0%9B/id1434372636?mt=8'
        }else{
                window.location.href='https://play.google.com/store/apps/details?id=com.yes24.seywallet'
        }

  }

// componentDidMount() {
//        document.documentElement.scrollTop = 0
//  }
  
//  render(){
//        console.log('#####');
//        return (<section>Redirecting...</section>);
//  }
}

export default AppPage;
