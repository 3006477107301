import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import MenuInitRun from './MenuInit.run';
import utilService from "../../common/utilService";
import apiService from "../../apis/apiService"
import BigNumber from 'bignumber.js';

class HomeHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sendTokenAmount: 0,
        }
    };

    componentDidUpdate(nextProps, nextState) {
    };

    componentDidMount() {
        MenuInitRun()
        var $ = window.$
        $(".navigation .menu > ul > li").hover(function () {
            $(this).find('.navi_sub').show();
        }, function () {
            $(this).find('.navi_sub').hide();
        });

        $("#navigation .menu > li").hover(function () {
            $(this).find('.navi_sub').show();
        }, function () {
            $(this).find('.navi_sub').hide();
        });

        this.getSentTokenAmount()
    }

    getSentTokenAmount() {
        apiService.getSentTokenAmount((err, rets) => {
            console.log('getSentTokenAmount', err, rets)
            if (!err) {
                this.setState({
                    sendTokenAmount: new BigNumber(rets.result_sent_token_amount).toFormat(0)
                })
                this.forceUpdate()
                var $ = window.$
                $('.counter').counterUp({
                    delay: 50,
                    time: 2000
                });
            }
        })
    }

    logout(e) {
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }
        localStorage.token = null
        localStorage.user = null

        this.context.router.history.replace('/login');
    }

    changeLang(lang, e) {
        console.log(lang, e)
        if (e) {
            e.stopPropagation()
            e.preventDefault()
        }
        localStorage.lang = lang
        window.location.href = window.location.href
    }

    render() {
        var user = localStorage.user ? JSON.parse(localStorage.user) : null
        var isLogin = user && user.user_id
        var lang = localStorage.lang || 'ko'
        return (
            <section className="home">
                <div className="full_height bg_image" style={{ backgroundImage: "url('assets/images/main-bg.jpg')", height: '100vh' }}>
                    <div id="particles-js"></div>
                    <div id="header" className="ts">
                        <div className="row">
                            <div id="top_bar" className="top_bar ts">
                                {!isLogin && <ul className="top_menu">
                                    <li><Link to={'/login'}>{utilService.getLangByCode('menu.login', '로그인')}</Link></li>
                                </ul>}
                                {isLogin && <ul className="top_menu">
                                    <li><a href={'/logout'} onClick={this.logout.bind(this)}>{utilService.getLangByCode('menu.logout', '로그아웃')}</a></li>
                                </ul>}
                            </div>
                            <div className="navigation">
                                <div className="menu first">

                                    <ul>
                                        <span>
                                            <Link to="/" className="menu_color"><i className="fas fa-home menu-text"></i>{utilService.getLangByCode('menu.home', 'HOME')}</Link>
                                        </span>
                                        <span>
                                            <Link to="/send" className="menu_color"><i className="far fa-paper-plane menu-text"></i>{utilService.getLangByCode('menu.sendmoney', '송금하기')}</Link>
                                        </span>
                                        <span>
                                            <Link to="/usage" className="menu_color"><i className="far fa-file-alt menu-text"></i>{utilService.getLangByCode('menu.usage', '사용내역')}</Link>
                                        </span>
                                        <span>
                                            <Link to="/deals" className="menu_color"><i className="far fa-file-powerpoint menu-text"></i>{utilService.getLangByCode('menu.deals', '거래내역')}</Link>
                                        </span>
                                        <li className="li_community ">
                                            <Link to="/notice" className="menu_color"><i className="fas fa-headset menu-text"></i>{utilService.getLangByCode('menu.support', '고객지원')}</Link>
                                            <div className="navi_sub">
                                                <ul>
                                                    <li ><Link to="/notice">{utilService.getLangByCode('menu.notice', '공지사항')}</Link></li>
                                                    <li ><Link to="/faq">{utilService.getLangByCode('menu.faq', 'FAQ')}</Link></li>
                                                    <li ><Link to="/sendmail">{utilService.getLangByCode('menu.qna', '1:1 문의')}</Link></li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <Link to="/" id="m_logo"><img src="assets/images/logo_m_x2.png" alt="SEY TOKEN LOGO" /></Link>
                            <a href="#" id="m_nav"><span className="line"></span></a>
                        </div>
                    </div>
                    <div id="m_menu" className="m_menu">
                        <div className="m_menu_wrap">
                            <div className="m_menu_header">
                                <div className="pull-left">
                                    {!isLogin && <ul className="m_menu_top">
                                        <li><Link to={'/login'}>{utilService.getLangByCode('menu.login', '로그인')}</Link></li>
                                    </ul>}
                                    {isLogin && <ul className="m_menu_top">
                                        <li><a href={'/logout'} onClick={this.logout.bind(this)}>{utilService.getLangByCode('menu.logout', '로그아웃')}</a></li>
                                    </ul>}
                                </div>

                                <div className="m_menu_close pull-right">
                                    <a href="#" id="m_menu_close" className="m_close" title="Close" />
                                </div>
                            </div>

                            <div className="m_menu_con">
                            </div>
                        </div>
                    </div>

                    <div className="display-table">
                        <div className="display-table-cell" style={{ paddingTop: '5%' }} >
                            <dt style={{ color: 'white', fontWeight: 'bold' }}>
                                <img src="assets/images/sey_new.png" alt="sey 로고" className="sey_new" />&nbsp;
                            </dt>

                            <h2 className="mgt_40" style={{ fontsize: '25px', marginTop: '20px' }}>
                                {utilService.getLangByCode('msg8', "sey Coin은 예스24의 메인넷 sey Chain 기반의 가상화폐입니다.")}
                            </h2>

                            <h4>
                                <br /> {utilService.getLangByCode('msg15', "발행량 ")}<span className="counter">{this.state.sendTokenAmount}</span>
                            </h4>
                        </div>

                    </div>
                </div>
            </section>
        );
    };
};

HomeHeader.contextTypes = {
    router: PropTypes.object.isRequired
}

export default HomeHeader;
