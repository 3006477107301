import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import utilService from "../../common/utilService";

class Footer extends Component {
    render() {
        return (
            <div>
                <div id="footer">
                    <a href="#" id="ic_up"><img src="assets/images/ic_up.png" alt="up" /></a>
                </div>

                <footer className="footer_tb">
                    <div className="container text-center">
                        <img src="assets/images/s_logo.png" alt="하단 로고"/><br/>
                        <p>Based on ORBS Technology.</p>
                        <span><Link to={'/policy'}>{utilService.getLangByCode('label.terms', '이용약관')}</Link></span><span><Link to={'/privacy'}>{utilService.getLangByCode('label.privacy', '개인정보 처리방침')}</Link></span>
                        <p>Copyright ⓒ YES24 Corp. All Rights Reserved.</p>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
