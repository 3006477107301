// var serverAddress = "http://orbs.kotech.biz";
var serverAddress = (window.location.hostname == 'localhost') ? 'http://localhost:3010' : window.location.protocol + '//' + window.location.hostname;
var $ = window.$
var apiService = {
    login: function (username, password, cb) {
        $.ajax({
            url: serverAddress + "/frontLogin",
            method: 'POST',
            data: JSON.stringify({
                username: username,
                password: password
            }),
            contentType: "application/json",
            dataType: "json"
        }).then((res) => {
            var token = res.token
            localStorage.token = token
            console.log(token)
            $.ajax({
                url: serverAddress + "/front/getMyInfo?_t=" + Date.now(),
                method: 'GET',
                headers: {
                    Authorization: 'bearer ' + token
                },
                dataType: "json"
            }).then((user) => {
                console.log('login user', user)
                localStorage.user = JSON.stringify(user)
                if (cb) cb(null, user)
            }, (error) => {
                cb(error.responseJSON)
            })
        }, (error) => {
            cb(error.responseJSON)
        })
    },
    getUserInfo: function (cb) {
        $.ajax({
            url: serverAddress + "/front/getMyInfo?_t=" + Date.now(),
            method: 'GET',
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            dataType: "json"
        }).then((user) => {
            localStorage.user = JSON.stringify(user)
            if (cb) cb(null, user)
        }, (error) => {
            cb(error.responseJSON)
        })
    },
    sendToken: function (data, cb) {
        $.ajax({
            url: serverAddress + "/front/sendToken",
            method: 'POST',
            data: JSON.stringify(data),
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            contentType: "application/json",
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    createWalletAddress: function (cb) {
        $.ajax({
            url: serverAddress + "/front/createWalletAddress?_t=" + Date.now(),
            method: 'GET',
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    getMyInfo: function (cb) {
        $.ajax({
            url: serverAddress + "/front/getMyInfo?_t=" + Date.now(),
            method: 'GET',
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    getNotices: function (query, pageNum, pageSize, cb) {
        var param = '?query=' + query + '&pageNum=' + pageNum + '&pageSize=' + pageSize + "&_t=" + Date.now();
        $.ajax({
            url: serverAddress + "/frontNoAuth/getNotices" + param,
            method: 'GET',
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    getNotice: function (seq, cb) {
        $.ajax({
            url: serverAddress + "/frontNoAuth/getNotice?seq=" + seq + "&_t=" + Date.now(),
            method: 'GET',
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    getFaqs: function (type, query, pageNum, pageSize, cb) {
        var param = '?type=' + type + '&query=' + query + '&pageNum=' + pageNum + '&pageSize=' + pageSize + "&_t=" + Date.now()
        $.ajax({
            url: serverAddress + "/frontNoAuth/getFaqs" + param,
            method: 'GET',
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    getFaq: function (seq, cb) {
        $.ajax({
            url: serverAddress + "/frontNoAuth/getFaq?seq=" + seq + "&_t=" + Date.now(),
            method: 'GET',
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    getTransfers: function (fromDate, toDate, pageNum, pageSize, cb) {
        var param = '?fromDate=' + fromDate + '&toDate=' + toDate + '&pageNum=' + pageNum + '&pageSize=' + pageSize + "&_t=" + Date.now()
        $.ajax({
            url: serverAddress + "/front/getTransfers" + param,
            method: 'GET',
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    getUsages: function (fromDate, toDate, pageNum, pageSize, cb) {
        var param = '?fromDate=' + fromDate + '&toDate=' + toDate + '&pageNum=' + pageNum + '&pageSize=' + pageSize + "&_t=" + Date.now()
        $.ajax({
            url: serverAddress + "/front/getUsages" + param,
            method: 'GET',
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    sendMail: function (data, cb) {
        $.ajax({
            url: serverAddress + "/frontNoAuth/sendMail" + "?_t=" + Date.now(),
            method: 'POST',
            data: JSON.stringify(data),
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            contentType: "application/json",
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    getSentTokenAmount: function (cb) {
        $.ajax({
            url: serverAddress + "/frontNoAuth/getSentTokenAmount" + "?_t=" + Date.now(),
            method: 'GET',
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            contentType: "application/json",
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error)
        })
    },
    checkReceiverId: function (userId, cb) {
        $.ajax({
            url: serverAddress + "/front/checkReceiverId?_t=" + Date.now(),
            method: 'POST',
            data: JSON.stringify({
                userId: userId
            }),
            headers: {
                Authorization: 'bearer ' + localStorage.token
            },
            contentType: "application/json",
            dataType: "json"
        }).then((res) => {
            if (cb) cb(null, res)
        }, (error) => {
            cb(error.responseJSON)
        })
    }
}

export default apiService
