
import React, { Component } from 'react';
import {Link} from "react-router-dom"
import utilService from "../../common/utilService"

class PrivacyPage extends Component {

    componentDidMount() {
        document.documentElement.scrollTop = 0
    };

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        var user = localStorage.user?JSON.parse(localStorage.user):null
        var isLogin = user && user.user_id

        return (
            <div id="contents">
                <div id="sub_menu" className="sub_menu">
                    <div className="row">
                        {!isLogin&&<ul className="clearfix col3">
                            <li><Link to={'/login'}>{utilService.getLangByCode('label.login', '로그인')}</Link></li>
                            <li><Link to={'/policy'}>{utilService.getLangByCode('label.terms', '이용약관')}</Link></li>
                            <li className='act'><Link to={'/privacy'}>{utilService.getLangByCode('label.privacy', '개인정보처리방침')}</Link></li>
                        </ul>}
                        {isLogin&&<ul className="clearfix col2">
                            <li><Link to={'/policy'}>{utilService.getLangByCode('label.terms', '이용약관')}</Link></li>
                            <li className='act'><Link to={'/privacy'}>{utilService.getLangByCode('label.privacy', '개인정보처리방침')}</Link></li>
                        </ul>}
                    </div>
                </div>
                <section className="policy clearfix">
                    <p className="mgt_70">예스이십사 주식회사가 운영하는 사이버몰(이하 "YES24")은 이용자들의 개인정보를 매우 중요하게 생각하며 다음과 같이 개인정보처리방침을 가지고 있습니다. 이를 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보 보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. 본 개인정보처리방침은 개인정보보호와 관련한 법률 또는 지침의 변경, 회사 정책의 변화에 따라 달라질 수 있으니 이용자께서는 "YES24" 방문시 수시로 확인하시기 바랍니다.</p>

                    <ul className="mgt_30">
                        <li>1. 개인정보의 수집, 이용 목적</li>
                        <li>2. 개인정보수집에 대한 동의</li>
                        <li>3. 개인정보의 수집항목 및 수집방법</li>
                        <li>4. 비회원 개인정보보호</li>
                        <li>5. 개인정보의 목적 외 사용 및 제3자에 대한 제공</li>
                        <li>6. 개인정보의 열람, 정정, 동의철회(회원탈퇴) 방법</li>
                        <li>7. 개인정보의 보유기간 및 이용기간</li>
                        <li>8. 개인정보의 파기 절차 및 방법</li>
                        <li>9. 쿠키의 운영</li>
                        <li>10. 개인정보보호를 위한 기술적/관리적 대책</li>
                        <li>11. 의견수렴 및 불만처리</li>
                        <li>12. 아동에 대한 정보보호에 관한 사항</li>
                        <li>13. 개인정보보호책임자 등</li>
                        <li>14. 개인정보 처리 위탁관리</li>
                        <li>15. 개인 위치정보의 보호</li>
                        <li>16. 모바일 어플리케이션 개인정보</li>
                        <li>17. 고지의무</li>
                    </ul>


                    <h5>1. 개인정보의 수집, 이용 목적</h5>
                    <p>"개인정보"라 함은 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.
                        개인정보 수집은 "YES24" 서비스를 이용하고자 하는 이용자의 의사를 확인하며, 최적의 서비스를 제공하고자 하는 것입니다. "YES24"는 인터넷 전자상거래회사로서 전자상거래 및 이와 관련한 다양한 서비스를 이용자들의 특성, 기호에 맞추어 제공하고 이용자가 "YES24"의 서비스를 이용함에 따라 일어나는 문제를 해결하기 위해 일부 한정된 범위내에서 개인정보를 수집하고 있습니다.
                        "YES24"가 이용자의 개인정보를 수집하는 목적은 다음과 같습니다.</p>


                    <table className="tg mgt_30" width="100%">
                        <colgroup>
                            <col width="30%"/>
                            <col width="70%"/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>구분</th>
                            <th>이용 목적</th>
                        </tr>
                        <tr>
                            <td rowSpan={6} style={{textAlign: 'center'}}>회원관리</td>
                            <td>회원제 서비스 이용에 따른 본인확인, 개인식별</td>
                        </tr>
                        <tr>
                            <td>불량회원의 부정이용 방지와 비인가 사용 방지, 중복가입방지</td>
                        </tr>
                        <tr>
                            <td>가입의사 확인, 가입 및 가입횟수 제한</td>
                        </tr>
                        <tr>
                            <td>만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인</td>
                        </tr>
                        <tr>
                            <td>고객상담, 불만처리 등 민원 처리, 분쟁조정 해결을 위한 기록보존</td>
                        </tr>
                        <tr>
                            <td>고지사항 전달</td>
                        </tr>
                        <tr>
                            <td rowSpan={4} style={{textAlign: 'center'}}>서비스 제공에 관한 계약의 이행 및 서비스  제공에 따른 요금정산</td>
                            <td>회원간의  거래 서비스 제공</td>
                        </tr>
                        <tr>
                            <td>콘텐츠 제공, 이벤트 당첨 결과안내 및 경품배송</td>
                        </tr>
                        <tr>
                            <td>금융거래 본인 인증 및 금융 서비스</td>
                        </tr>
                        <tr>
                            <td>물품 배송, 구매 및 요금 결제</td>
                        </tr>
                        <tr>
                            <td rowSpan={7} style={{textAlign: 'center'}}>마케팅 및 광고에 활용</td>
                            <td>이용자에게  최적화된 서비스 제공</td>
                        </tr>
                        <tr>
                            <td>신규 서비스 및 상품 개발 및 특화</td>
                        </tr>
                        <tr>
                            <td>인구통계학적 특성에 따른 서비스 제공 및 광고 게재</td>
                        </tr>
                        <tr>
                            <td>접속 빈도 파악, 서비스 이용 현황 통계/분석</td>
                        </tr>
                        <tr>
                            <td>정기 간행물 발송, 새로운 상품 또는 서비스 안내</td>
                        </tr>
                        <tr>
                            <td>고객 관심사에 부합하는 웹서비스 및 이벤트 기획</td>
                        </tr>
                        <tr>
                            <td>경품행사, 이벤트 등 광고성 정보 전달 또는 회원 참여공간 운영</td>
                        </tr>
                        </tbody>
                    </table>



                    <h5>2. 개인정보수집에 대한 동의</h5>
                    <p>"YES24"는 회원가입시 '서비스 이용약관'과 '개인정보처리방침'에 동의하는 절차를 마련하고 있으며, 동의의 의사표시가 없으면 회원으로 가입하실 수 없습니다.</p>

                    <h5>3. 개인정보의 수집항목 및 수집방법</h5>
                    <ul>
                        <li>(1) 수집항목</li>
                        <ul>
                            <li>첫째, "YES24"는 주문조회용 정보, 원활한 고객상담, 각종서비스의 제공을 위해 회원가입 또는 비회원 주문시 아래의 개인정보 항목을 수집하고 있습니다.</li>

                            <ul>
                                <li>① 일반/14세미만어린이 회원 :</li>
                                <ul>
                                    <li>- 필수항목 : 본인인증값, 법정 대리인의 본인인증값, 성명, 아이디, 비밀번호, 닉네임, 생년월일, 성별, 주소, 전화번호, 이메일</li>
                                    <li>- 선택항목 : 휴대전화번호, 직업, 결혼여부, 결혼기념일, 자녀유무</li>
                                </ul>
                                <li>② 법인회원 :</li>
                                <ul>
                                    <li>- 필수항목 : 사업자등록번호, 법인명, 업체유형, 업종 및 형태, 대표자명, 아이디, 비밀번호, 사업장 소재지, 전화번호</li>
                                    <li>- 선택항목 : 담당자명, 담당자 부서, 담당자 전화번호, 담당자 이메일</li>
                                </ul>
                                <li>③ 외국인회원 :</li>
                                <ul>
                                    <li>- 필수항목 : 이름, 외국인등록번호, 아이디, 비밀번호, 닉네임, 성별, 생년월일, 국적, 이메일, 주소, 전화번호</li>
                                    <li>- 선택항목 : 휴대전화번호, 직업, 결혼여부, 결혼기념일, 자녀유무</li>
                                </ul>
                                <li>④ 해외거주회원 :</li>
                                <ul>
                                    <li>- 필수항목 : 성명, 영문성명, 아이디, 비밀번호, 닉네임, 생년월일, 성별, 국적, 주소, 이메일, 전화번호</li>
                                    <li>- 선택항목 : 휴대전화번호, 직업, 결혼여부, 결혼기념일, 자녀유무</li>
                                </ul>
                                <li>⑤ 비회원 :</li>
                                <ul>
                                    <li>- 필수항목 : 성명, 비밀번호, 주소, 이메일, 휴대전화번호</li>
                                    <li>- 선택항목 : 전화번호</li>
                                </ul>
                            </ul>


                            <li>둘째, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.</li>
                            <ul>
                                <li>- IP Address, 쿠키, 방문 일시, OS종류, 브라우져 종류 서비스 이용 기록, 불량 이용 기록</li>
                            </ul>
                            <li>셋째, 부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만. 개인정보 추가 수집에 대해 동의를 받는 경우에 한해서만, 아래와 같은 정보들이 수집될 수 있습니다.</li>
                            <ul>
                                <li>- 경품 등 배송 등을 위한 배송지, 수령자명, 유선전화번호, 휴대전화번호, E-Mail 등</li>
                            </ul>
                            <li>넷째, 유료 서비스 이용 과정에서 아래와 같은 정보들이 수집될 수 있습니다.</li>
                            <ul>
                                <li>- 신용카드 결제 시 : 카드사명, 카드번호 등</li>
                                <li>- 휴대전화 결제 시 : 이동전화번호, 통신사, 결제승인번호, 이메일주소 등</li>
                                <li>- 계좌이체 시 : 은행명, 계좌번호 등</li>
                                <li>- 상품권 이용 시 : 상품권 번호</li>
                                <li>- 환불 시 : 환불계좌정보(은행명, 계좌번호, 예금주명)</li>
                            </ul>
                            <li>다섯째, 이용자의 기본적 인권을 침해할 우려가 있는 인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등의 정보는 이용자의 동의 또는 법령에 의한 경우 이외에는 수집하지 않습니다.</li>
                        </ul>

                        <li>(2) 개인정보 수집방법</li>
                        <ul>
                            <li>"YES24"는 홈페이지(회원가입, 비회원주문, 1:1친절상담)와 전화, 팩스, 이메일, 이벤트응모, 제휴사로부터의 제공, 생성정보 수집틀을 통해 개인정보를 수집하고 있습니다.</li>
                        </ul>
                    </ul>



                    <h5>4. 비회원 개인정보보호</h5>
                    <ul>
                        <li>(1) "YES24"는 비회원 주문의 경우에도 배송, 대금결제, 주문내역 조회 및 구매확인, 실명여부 확인을 위하여 필요한 개인정보만을 요청하고 있으며, 이 경우 그 정보는 대금결제 및 상품의 배송에 관련된 용도 이외에는 다른 어떠한 용도로도 사용되지 않습니다.</li>
                        <li>(2) 비회원의 개인정보 수집/이용 목적 및 항목</li>

                        <ul>
                            <li>① 성명</li>
                            <li>② 원활한 구매/판매 진행, 본인의사의 확인, 불만 및 분쟁처리, 고지 및 정보 안내 등 : 전화번호, 이동전화번호, e-mail</li>
                            <li>③ 대금결제서비스의 제공 등 : 은행계좌정보, 신용카드정보</li>
                            <li>④ 상품, 경품배송 : 주소, 전화번호, e-mail</li>
                            <li>⑤ 부정 이용방지, 전자금융거래기록보관 : IP address, 방문일시</li>
                        </ul>

                        <li>(3) 비회원 서비스 이용의 경우에는 회원 서비스 내용을 제외한 "YES24" 개인정보처리방침의 모든 내용이 동일하게 적용됩니다.</li>
                        <li>(4) "YES24"는 회원의 개인정보와 동일한 수준으로 비회원의 개인정보를 보호합니다.</li>
                    </ul>


                    <h5>5. 개인정보의 목적 외 사용 및 제3자에 대한 제공</h5>

                    <ul>
                        <li>(1) "YES24"는 인터넷서비스화면을 통하여 공개된 정보를 제외하고는 이용자의 개인정보를 "YES24"가 제공하는 인터넷 서비스 외의 용도로 사용하거나 이용자의 동의 없이 제3자에게 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</li>

                        <ul>
                            <li>① 이용자가 사전에 동의한 경우</li>
                            <li>② 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 지방세법, 소비자보호법, 한국은행법, 형사소송법 등 법령에 특별한 규정이 있는 경우</li>
                            <li>③ 서비스제공에 따른 요금정산을 위하여 필요한 경우</li>
                            <li>④ 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정개인을 식별할 수 없는 형태로 제공하는 경우.</li>
                        </ul>

                        <li>(2) "YES24"는 이용자에게 보다 더 나은 서비스를 제공하기 위하여 개인정보를 제휴사에 제공하거나 제휴사 등과 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 이용자께 제휴사 등이 누구인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 언제까지 어떻게 보호, 관리되는지에 대해 개별적으로 고지하여 동의를 구하는 절차를 거치게 되며, 이용자께서 동의하지 않은 경우에는 제휴사 등에게 제공하거나 제휴사 등과 공유하지 않습니다. 또한 이용자가 일단 개인정보의 제공에 동의하더라도 언제든지 그 동의를 철회할 수 있습니다.</li>
                    </ul>

                    <div style={{padding : '0 30px 0 5px'}}>
                        <table className="tg mgt_10 mgb_10 mgl_30" width="100%">
                            <colgroup>
                                <col width="20%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th>구분</th>
                                <th>개인정보 제공받는 자</th>
                                <th>개인정보 이용 목적</th>
                                <th>개인정보 제공 항목</th>
                                <th>보유 및 이용 기간</th>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center'}}>도서</td>
                                <td style={{textAlign: 'center'}}>[업체리스트]</td>
                                <td rowSpan={5} style={{textAlign: 'center'}}>주문상품의 배송, 고객상담 및 불만처리</td>
                                <td rowSpan={5} style={{textAlign: 'center'}}>성명, 주소, 연락처</td>
                                <td rowSpan={5}>개인정보 이용목적 달성시까지 (단, 관계 법령의 규정에 의해 보존의 필요가 있는 경우 및 사전 동의를 득한 경우는 해당 기간까지)</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center'}}>GIFT, CD/LP,<br/> DVD/Blu-ray</td>
                                <td style={{textAlign: 'center'}}>[업체리스트]</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center'}}>중고샵</td>
                                <td style={{textAlign: 'center'}}>[업체리스트]</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center'}}>영화</td>
                                <td style={{textAlign: 'center'}}>[업체리스트]</td>
                            </tr>
                            <tr>
                                <td style={{textAlign: 'center'}}>공연</td>
                                <td style={{textAlign: 'center'}}>[업체리스트]</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <ul>
                        <li>(3) "YES24"에 회원가입을 하지않고 "YES24"의 서비스를 이용하는 경우 입력하신 지불인 정보 및 수령인 정보는 대금결제 및 상품배송에 관련한 용도 외에는 다른 용도로 사용되지 않습니다.</li>
                    </ul>



                    <h5>6. 개인정보의 열람, 정정, 동의철회(회원탈퇴) 방법</h5>
                    <ul>
                        <li>(1) 이용자는 YES24에서 로그인하여 ‘마이페이지 > 정보내역 > 회원정보관리’에서 개인정보를 열람/정정하거나, 개인정보보호책임자 및 담당자에게 서면, 전화, 또는 e-mail로 연락하시면 지체없이 조치하겠습니다. 단, 회원아이디는 정정할 수 없으며, 성명은 개명으로 인한경우 본인인증 절차를 거친 후에 가능합니다.</li>
                        <li>(2) 이용자는 YES24에 개인정보 관련 처리 정지를 요구할 수 있습니다. 단, 아래와 같은 불가피한 경우 처리정지 요구를 거절할 수 있습니다.</li>
                        <ul>
                            <li>① 관련 법령의 예외 조항 또는 의무를 준수하기 위하여 불가피한 경우</li>
                            <li>② 다른 사람의 신체를 해할 우려가 있거나 다른 사람의 재산을 부당하게 침해할 우려가 있는 경우</li>
                            <li>③ 정보주체와 체결한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우</li>
                        </ul>
                        <li>(3) 이용자는 언제든지 개인정보의 수집, 이용에 대한 동의를 철회할 수 있습니다. YES24에 로그인하여 ‘마이페이지 > 정보내역 > 회원탈퇴’ 메뉴를 통해 직접 동의 철회할 수 있으며, YES24의 개인정보보호책임자에게 이메일 또는 서면으로 개인정보 삭제 등을 요청하면 처리해 드리겠습니다.</li>
                    </ul>


                    <h5>7. 개인정보의 보유 및 이용기간</h5>
                    <p>"YES24"가 이용자의 개인정보를 수집하는 경우 그 보유기간은 회원가입 하신 후 해지(탈퇴신청, 직권탈퇴 포함)시까지 입니다. 또한 회원 탈퇴시 "YES24"는 이용자의 수집된 개인정보가 열람 또는 이용될 수 없도록 파기 처리 됩니다. 개인정보가 제3자에게 제공된 경우에는 제3자에게도 파기하도록 지시합니다. 또한, 다음과 같은 경우 개인정보의 수집 목적 또는 제공받은 목적이 달성되면, 개인정보를 파기합니다.</p>

                    <ul>
                        <li>(1) 결제정보의 경우, 대금의 완제일 또는 채권소멸시효기간의 만료된 때</li>
                        <li>(2) 배송정보의 경우, 물품 또는 서비스가 인도되거나 제공된 때</li>
                        <li>(3) 단, 상법 및 ‘전자상거래등에서 소비자보호에 관한 법률’, 전자금융거래법, 여신전문금융업법 국세기본법, 법인세법, 부가가치세법 등 관련 법령 및 "YES24" 내부 규정에 의하여 성명, 주소 등 거래의 주체를 식별할 수 있는 정보와 거래 관련 권리 의무 관계의 확인 등에 한하여 서비스 이용에 관한 동의를 철회한 경우에도 이를 보존할 수 있으며, 다음과 같이 거래기록을 보관합니다.</li>
                        <ul>
                            <li>① 표시, 광고에 관한 기록</li>
                            <ul>
                                <li>-보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                                <li>-보존 기간 : 6개월</li>
                            </ul>
                            <li>② 계약 또는 청약철회 등에 관한 기록</li>
                            <ul>
                                <li>-보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                                <li>-보존 기간 : 5년</li>
                            </ul>
                            <li>③ 대금결제 및 재화등의 공급에 관한 기록</li>
                            <ul>
                                <li>-보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                                <li>-보존 기간 : 5년</li>
                            </ul>
                            <li>④ 소비자의 불만 또는 분쟁처리에 관한 기록</li>
                            <ul>
                                <li>-보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                                <li>-보존 기간 : 3년</li>
                            </ul>
                            <li>⑤ 수사기관이 압수수색영장 통해 요청 시 제공</li>
                            <ul>
                                <li>-보존 근거 : 통신비밀보호법</li>
                                <li>-보존 기간 : 6개월</li>
                            </ul>
                        </ul>
                    </ul>
                    <p>이용자의 개인정보는 회원가입 해지 시 또는 제공 목적 달성 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 보관된 후 기록을 재생할 수 없는 기술적 방법에 의하여 영구 삭제됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유 이외의 다른 목적으로 이용되지 않습니다.</p>



                    <h5>8. 개인정보의 파기 절차 및 방법</h5>
                    <p>YES24는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 보관기간 및 이용기간에 따라 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법, 시점은 다음과 같습니다.</p>
                    <ul>
                        <li>(1)파기절차</li>
                        <ul>
                            <li>① 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련에 따라(7.개인정보의 보유기간 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.</li>
                            <li>② 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</li>
                        </ul>
                        <li>(2)파기방법</li>
                        <ul>
                            <li>① 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</li>
                            <li>② 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</li>
                        </ul>
                        <li>(3)기타, 휴면계정의 개인정보 파기절차</li>
                        <ul>
                            <li>① YES24는 29조(개인정보의 파기)에 따라 회원 가입 후 1년 동안 서비스를 이용하지 않은 휴면계정은 탈퇴처리하고 이용자의 개인정보는 파기할 수 있습니다.</li>
                            <li>② 회사는 휴면계정 이용자에게 유효기간이 도래하기 30일 전까지는 이용자에게 통지하여야 하고 통지내용은 아래와 같습니다.</li>
                            <ul>
                                <li>- 개인정보의 파기 또는 분리 저장 관리되는 사실</li>
                                <li>- 파기 일시</li>
                                <li>- 파기되는 개인정보 항목</li>
                            </ul>
                            <li>③ 통지 방법은 이메일, 전화, 팩스 등을 이용할 수 있습니다.</li>
                            <li>④ 아래 예외사항에는 1년이 경과하더라도 파기하지 않고 보유할 수 있습니다.</li>
                            <ul>
                                <li>- 정보통신서비스제공자와 이용자가 계약으로 별도의 보존기간을 정한 경우</li>
                            </ul>
                        </ul>
                    </ul>



                    <h5>9. 쿠키의 운영</h5>
                    <p>"YES24"는 이용자에 대한 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 사용합니다. 쿠키는 웹사이트서버가 사용자의 컴퓨터 브라우저(인터넷 익스플로러, 사파리, 파이어폭스 등)에게 전송하는 소량의 문자열 정보입니다. 쿠키는 이용자의 컴퓨터는 식별하지만 이용자를 개인적으로 식별하지는 않습니다.</p>
                    <ul>

                        <li>(1) 쿠키의 운용</li>
                        <ul>
                            <li>① 개인의 관심 분야에 따라 차별화된 정보를 제공</li>
                            <li>② 회원과 비회원의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟마케팅에 활용</li>
                            <li>③ 관심 있게 둘러본 내용들에 대한 자취를 추적하여 다음 번 접속 때 개인맞춤서비스를 제공</li>
                            <li>④ 고객들의 습관을 분석하여 서비스 개편 등의 척도로 활용</li>
                        </ul>

                        <li>(2) 쿠키에 대한 선택권</li>

                        <ul>
                            <li>이용자의 웹브라우저를 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있습니다. 다만 쿠키의 저장을 거부하는 경우에는 "YES24"에서 로그인이 필요한 일부 서비스는 이용하실 수 없습니다.</li>
                            <li>쿠키 설치 허용 여부를 지정하는 방법(인터넷 익스플로러의 경우)은 다음과 같습니다.</li>
                            <ul>
                                <li>① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</li>
                                <li>② [개인정보 탭]을 클릭합니다.</li>
                                <li>③ [개인정보 설정]에서 “모든쿠키허용-낮음-보통-보통 높음-높음-모든쿠키차단”을 설정하시면 됩니다.</li>
                            </ul>
                            <li>쿠키는 브라우저의 종료시나 로그아웃시 만료됩니다.</li>
                        </ul>
                    </ul>


                    <h5>10. 개인정보보호를 위한 기술적/관리적 대책</h5>
                    <ul>

                        <li>(1) "YES24"는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조, 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 마련하여 적용하고 있습니다.</li>

                        <ul>
                            <li>① 이용자의 개인정보는 비밀번호 및 암호화된 정보에 의해 보호되고 있습니다. 그러나 이용자의 비밀번호나 개인정보는 공공장소에서의 인터넷사용 등 여러 방법으로 타인에게 알려질 가능성이 높으므로 이의 보호를 철저히 하는 것이 무엇보다 중요합니다. 그러므로 이용자께서도 개인의 정보를 타인에게 유출시키거나 제공하여서는 안되며, 자신의 개인정보를 책임 있게 관리하여야 합니다. 이러한 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 문제에 대해서 "YES24"는 책임을 지지 않습니다.</li>
                            <li>② 이용자의 개인정보는 기본적으로 비밀번호및 암호화된 정보에 의해 보호되며, 파일 및 전송 데이터를 암호화하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.</li>
                            <li>③ "YES24"는 백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있으며 백신프로그램은 주기적으로 업데이트 됩니다. 만일, 바이러스 침투가 될 경우 백신이 나오는 즉시 이를 제공함으로써 치료와 함께 개인정보가 침해되는 것을 방지하고 있습니다.</li>
                            <li>④ "YES24"는 안전한 암호화 알고리즘을 이용하여 네트워크 상의 개인정보를 전송할 수 있는 방식을 채택하고 있습니다.</li>
                            <li>⑤ "YES24"는 해킹 및 바이러스 등에 의하여 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해, 외부로부터의 침입탐지 및 침입차단 시스템을 두어 24시간 모니터링하며 관리하고 있습니다. 만일의 사태에 대비하여 침입탐지 시스템과 침입차단 시스템은 2중화로 되어 있습니다.</li>
                        </ul>
                        <li>(2) "YES24"는 이용자의 개인정보보호의 중요성을 인식하고 있으며 이를 위해 개인정보처리직원을 최소한으로 제한하고 있으며 개인정보보호책임자가 처리직원을 대상으로 교육을 주기적으로 실시하여 개인정보보호를 위해 최선을 다하고 있습니다. 또한 본 정책에 명시된 이행사항 및 관련 직원의 준수여부를 정기적으로 점검하여 위반내용이 있는 경우 이를 시정 또는 개선하고 기타 필요한 조치를 취하도록 하고 있습니다.</li>
                    </ul>


                    <h5>11. 의견수렴 및 불만처리</h5>
                    <p>"YES24"는 개인정보보호와 관련한 이용자들의 의견과 불만을 제기하여 원활하게 소통과 해결을 할 수 있는 고객상담창구를 운영하고 있습니다.</p>
                    <p>이용자와 "YES24"가 개인정보보호와 관련하여 분쟁이 발생하여 개인정보 침해에 관한 상담이 필요한 경우에는 한국인터넷진흥원 개인정보침해신고센터, 경찰청 사이버안전국 등으로 문의하실 수 있습니다.</p>


                    <table className="tg mgt_10 mgb_10" width="100%">
                        <colgroup>
                            <col width="30%"/>
                            <col width="45%"/>
                            <col width="25%"/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>항목</th>
                            <th>웹사이트주소</th>
                            <th>전화번호</th>
                        </tr>
                        <tr>
                            <td>개인정보침해신고센터</td>
                            <td>http://privacy.kisa.or.kr</td>
                            <td>118</td>
                        </tr>
                        <tr>
                            <td>대검찰청 첨단범죄수사과</td>
                            <td>http://www.spo.go.kr</td>
                            <td>02-3480-2000</td>
                        </tr>
                        <tr>
                            <td>경찰청 사이버안전국</td>
                            <td>http://cyber.go.kr</td>
                            <td>182</td>
                        </tr>
                        </tbody>
                    </table>



                    <h5>12. 아동에 대한 정보보호에 관한 사항</h5>
                    <ul>
                        <li>(1) 만14세 미만의 아동이 "YES24"에 회원가입을 하기 위해서는 법정대리인의 동의를 받아야 합니다.
                            이에 “YES24”는 법정대리인의 본인인증이 이루어져야만 회원가입이 완료됩니다.
                            수집된 아동의 법정대리인 개인정보는 동의여부를 확인하는 용도로만 사용합니다.</li>
                        <li>(2) "YES24"는 만14세 미만의 아동으로부터 수집한 개인정보에 대하여 법정대리인이 열람, 정정, 삭제 등을 요구하는 경우 지체 없이 필요한 조치를 취합니다.</li>
                        <li>(3) 열람, 정정, 삭제를 원하실 경우 개인정보 고충처리 담당부서로 연락을 주시면 법정 대리인임을 확인 후 처리해 드립니다.</li>
                    </ul>


                    <h5>13. 개인정보보호책임자 등</h5>
                    <ul>
                        <li>(1)"YES24"는 개인정보보호책임자를 지정하여 담당부서에서 이용자들의 소중한 개인정보보호 업무에 최선의 노력을 다하고 있습니다.
                            이용자께서 "YES24"에 개인정보와 관련한 문의, 의견, 불만을 제기하고자 하실 경우에는 아래의 연락처나 이메일로 문의해주시기 바랍니다. 신속하고 성실하게 답변해 드리겠습니다.</li>
                    </ul>

                    <div style={{padding : '0 20px 0 40px'}}>
                        <table className="tg" width="100%">
                            <colgroup>
                                <col width="30%"/>
                                <col width="70%"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th colSpan={2}>개인정보보호책임자</th>
                            </tr>
                            <tr>
                                <td>성　　명</td>
                                <td>한광일</td>
                            </tr>
                            <tr>
                                <td>소속부서</td>
                                <td>IT서비스본부</td>
                            </tr>
                            <tr>
                                <td>직　　위</td>
                                <td>CTO</td>
                            </tr>
                            <tr>
                                <td>전화번호</td>
                                <td>1544-3800</td>
                            </tr>
                            <tr>
                                <td>이 메 일</td>
                                <td>privacy@yes24.com</td>
                            </tr>
                            <tr>
                                <th colSpan={2}>개인정보 고충처리 담당부서</th>
                            </tr>
                            <tr>
                                <td>담당부서</td>
                                <td>고객만족센터</td>
                            </tr>
                            <tr>
                                <td>전화번호</td>
                                <td>1544-3800</td>
                            </tr>
                            <tr>
                                <td>이 메 일</td>
                                <td>privacy@yes24.com</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>



                    <h5>14. 개인정보 처리 위탁관리</h5>
                    <p>"YES24"는 원활하고 편리한 서비스를 위하여, 최소한의 제한된 범위에서 개인정보를 외부 전문업체에 위탁하여 운영하고 있습니다. 보유 및 이용기간은 회원탈퇴 및 위탁 계약 만료 시 까지이며, 위탁 계약시 개인정보가 안전하게 관리될 수 있도록 관련 사항들을 별도로 규정하고 있습니다.</p>
                    <p>▶ YES24의 개인정보 위탁처리 업체 및 위탁 목적, 업무 내용 확인</p>


                    <h5>15. 개인 위치정보의 보호</h5>
                    <ul>
                        <li>(1) "YES24"는 위치기반 서비스 제공을 목적으로 위치정보를 수집, 이용할 수 있습니다.</li>
                        <li>(2) 위치정보 수집, 이용, 제공은 (1)의 서비스 제공 목적 외의 다른 용도로 사용되지 않으며, 이용목적 달성 후 보존 기간의 근거가 없는 정보는 파기 합니다.</li>
                        <li>(3) 개인 위치정보 보호에 대한 회사의 의무 및 고객의 권리는 ‘위치정보의 보호 및 이용 등에 관한 법률’ 및 관련 법령을 따릅니다.</li>
                    </ul>


                    <h5>16. 모바일 어플리케이션 개인정보</h5>
                    <ul>
                        <li>(1) “예스24”는 모바일 어플리케이션 서비스를 운영, 제공하기 위하여 아래 정보를 생성, 수집, 이용하고 저장할 수 있습니다.</li>
                        <li>(2) 스마트폰 등 모바일 앱을 통해 서비스를 이용할 경우 이용자에게 개인정보 수집, 이용에 대한 동의를 받고, 고지한 범위 내에서 정보 수집과 이용을 합니다.</li>
                        <ul>
                            <li>- 단말기 로그인 데이터</li>
                            <li>- 위치 정보</li>
                            <li>- 단말기 ID, 모델, OS, 브라우져 종류</li>
                            <li>- 사진, 미디어, 파일 등 의 정보</li>
                            <li>- 그 외 추가 수집에 대하여 동의 받은 정보</li>
                        </ul>

                        이용자는 언제든지 이용자 설정에 접근하고 변경할 수 있으며, 단말기 옵션, 설정 메뉴로부터 이용자의 이력을 삭제할 수 있습니다. 단, 단말기에서 앱을 삭제하더라도 이용자의 회원 계정은 삭제되지 않으므로, 더 이상의 이용을 원치 않을 경우 웹페이지를 통해 회원탈퇴를 해주시면 됩니다.
                    </ul>


                    <h5>17. 고지의무</h5>

                    <p>현 개인정보처리방침은 2002년 11월 29일 제정되었으며, 정부 및 회사의 정책 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 홈페이지를 통해 즉시 공지하며, 이 정책은 공지한 날로부터 시행됩니다.</p>
                    <p>개인정보 처리방침 버전번호 :V5.4</p>
                    <p>개인정보 처리방침 변경공고일자: 2017. 12. 03</p>
                    <p>개인정보 처리방침 시행일자: 2017. 12. 10</p>
                </section>
            </div>
        );
    };
};

export default PrivacyPage;
