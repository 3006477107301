import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';

/*
  Load locales that need to be supported
*/
//import en from 'react-intl/locale-data/en';
//import ko from 'react-intl/locale-data/ko';

import './index.css';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
import Cache from './common/Cache'

//addLocaleData([...ko, ...en]);

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();

