import React, { Component } from 'react';
import { Pagination } from 'react-bootstrap'
import { Link } from "react-router-dom"
import PropTypes from 'prop-types';
import pubsub from 'pubsub-js';

import apiService from "../../apis/apiService"
import utilService from "../../common/utilService"
import * as qs from 'query-string';

class NoticePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            notices: [],
            activePage: Number(qs.parse(this.props.location.search).activePage || 1),
            numOfPages: 1,
            totalNumberOfRecords: 0
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0
        this.searchPage(this.state.activePage)

        var user = localStorage.user ? JSON.parse(localStorage.user) : null
        if (user && user.user_id) {
            pubsub.publish('refreshUserInfo');
        }
    };

    searchPage(page) {
        apiService.getNotices(this.refs.query.value, page, 5, (err, rets) => {
            console.log('getNotices', err, rets)
            if (!err) {
                this.setState({
                    activePage: page,
                    notices: rets.rows,
                    numOfPages: Math.ceil(rets.count / 5),
                    totalNumberOfRecords: rets.count
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
    }

    handlePageSelect(eventKey) {
        this.searchPage(eventKey)
    }

    goDetail(item) {
        this.context.router.history.push('/noticeDetail?seq=' + item.seq + '&activePage=' + this.state.activePage);
    }

    render() {
        var { notices, activePage, numOfPages, totalNumberOfRecords } = this.state
        var lang = utilService.getCurrentLang()
        return (
            <div>
                <section className="contact padding-top-90 padding-bottom-90 primary_bg" id="contact">
                    <div className="row" style={{ maxWidth: 1220 }}>
                        <div className="col-md-12">
                            <div className="kv_csr" id="section_csr">
                                <div className="row">
                                    <h2 className="stt01"><i className="fa fa-quote-left" style={{ marginRight: 5 }}></i>{utilService.getLangByCode('label.notice', '공지사항')}</h2>
                                    <p className="stxt01">{utilService.getLangByCode('msg1', 'sey코인 관련 소식을 전해 드립니다.')}</p>
                                </div>
                            </div>

                            <div className="box">
                                <div className="p-2">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="fr">
                                                <button type="button" className="btn btn-primary" onClick={this.searchPage.bind(this, 1)}>{utilService.getLangByCode('label.search', '검색')}</button>
                                            </div>
                                            <div className="input-group fr">
                                                <input type="text" className="form-control3" ref="query" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="usage_table">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover">
                                            <colgroup>
                                                <col width="10%" />
                                                <col width="65%" />
                                                <col width="25%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>{utilService.getLangByCode('label.number', '번호')}</th>
                                                    <th>{utilService.getLangByCode('label.title', '제목')}</th>
                                                    <th>{utilService.getLangByCode('label.regdate', '등록일')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    notices.map((item, index) => {
                                                        return <tr style={{ cursor: 'pointer' }} key={index} onClick={this.goDetail.bind(this, item)}>
                                                            <td>{index + 1}</td>
                                                            <td style={{ textAlign: 'left' }}>{lang == 'ko' ? item.subject : item.subject_en}</td>
                                                            <td>{utilService.getDate(item.reg_dt)}</td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <footer className="table_footer_background bdt">
                                    <div className="row">
                                        <div className="col-sm-4">
                                        </div>
                                        <div className="col-sm-4" style={{ textAlign: 'center' }}>
                                            <Pagination
                                                className={totalNumberOfRecords === 0 ? 'hidden' : 'shown'}
                                                prev
                                                next
                                                first
                                                last
                                                ellipsis
                                                maxButtons={5}
                                                items={numOfPages}
                                                activePage={activePage}
                                                onSelect={this.handlePageSelect.bind(this)}>
                                            </Pagination>
                                        </div>
                                        <div className="col-sm-4">
                                        </div>
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        );
    };
};

NoticePage.contextTypes = {
    router: PropTypes.object.isRequired
}

export default NoticePage;
