import React, { Component } from "react"
import { Link } from "react-router-dom"
import MobileDetect from "mobile-detect"
import PropTypes from "prop-types"
import apiService from "../../apis/apiService"
import utilService from "../../common/utilService"

class HomePage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			notices: [],
			faqs: [],
			md: new MobileDetect(window.navigator.userAgent),
			popupDisplay: false
		}
	}

	componentDidMount() {
		document.documentElement.scrollTop = 0
		if (document.getElementById("particles-js")) {
			window.particlesJS("particles-js",
				{
					"particles": {
						"number": {
							"value": 80,
							"density": {
								"enable": true,
								"value_area": 800
							}
						},
						"color": {
							"value": "#ddd"
						},
						"shape": {
							"type": "circle",
							"stroke": {
								"width": 0,
								"color": "#888888"
							},
							"polygon": {
								"nb_sides": 5
							},
							"image": {
								"src": "img/github.svg",
								"width": 100,
								"height": 100
							}
						},
						"opacity": {
							"value": 0.5,
							"random": false,
							"anim": {
								"enable": false,
								"speed": 1,
								"opacity_min": 0.1,
								"sync": false
							}
						},
						"size": {
							"value": 5,
							"random": true,
							"anim": {
								"enable": false,
								"speed": 40,
								"size_min": 0.1,
								"sync": false
							}
						},
						"line_linked": {
							"enable": true,
							"distance": 150,
							"color": "#777",
							"opacity": 0.4,
							"width": 1
						},
						"move": {
							"enable": true,
							"speed": 6,
							"direction": "none",
							"random": false,
							"straight": false,
							"out_mode": "out",
							"attract": {
								"enable": false,
								"rotateX": 600,
								"rotateY": 1200
							}
						}
					},
					"interactivity": {
						"detect_on": "canvas",
						"events": {
							"onhover": {
								"enable": true,
								"mode": "repulse"
							},
							"onclick": {
								"enable": true,
								"mode": "push"
							},
							"resize": true
						},
						"modes": {
							"grab": {
								"distance": 400,
								"line_linked": {
									"opacity": 1
								}
							},
							"bubble": {
								"distance": 400,
								"size": 40,
								"duration": 2,
								"opacity": 8,
								"speed": 3
							},
							"repulse": {
								"distance": 200
							},
							"push": {
								"particles_nb": 4
							},
							"remove": {
								"particles_nb": 2
							}
						}
					},
					"retina_detect": true,
					"config_demo": {
						"hide_card": false,
						"background_color": "#b61924",
						"background_image": "",
						"background_position": "50% 50%",
						"background_repeat": "no-repeat",
						"background_size": "cover"
					}
				}
			)
		}


		window.kissuiScrollAnim.add(this.refs.img2, {
			"in": "fadeInUp"
		})
		this.loadData()
		var cookiedata = document.cookie
		if (cookiedata.indexOf("ncookie=done") == 0){			
			this.setState({
				popupDisplay: false
			})
		} else {
			this.setState({
				popupDisplay: true
		})
		}
	}

	componentDidUpdate(prevProps, prevState) {

	}

	loadData() {
		apiService.getNotices("", 1, 3, (err, rets) => {
			console.log("getNotices", err, rets)
			if (!err) {
				this.setState({
					notices: rets.rows,
				})
			}
		})
		apiService.getFaqs("", "", 1, 3, (err, rets) => {
			console.log("getFaqs", err, rets)
			if (!err) {
				this.setState({
					faqs: rets.rows,
				})
			}
		})
	}

	downloadWallet(e) {
		if (e) {
			e.stopPropagation()
			e.preventDefault()
		}

		alert(utilService.getLangByCode("msg26", "지갑 앱은 9월 1일 오픈 예정입니다."))
	}

	goNoticeDetail(item) {
		this.context.router.history.push("/faqDetail?seq=" + item.seq)
	}

	goFaqDetail(item) {
		this.context.router.history.push("/faqDetail?seq=" + item.seq)
	}  

    setCookie(name, value, expirehours) {
        var todayDate = new Date()
        todayDate.setHours(todayDate.getHours() + expirehours)
        document.cookie = name + "=" + escape(value) + "; path=/; expires=" + todayDate.toGMTString() + ";"
    }

    closeWin = () => {
        if (document.getElementById("pop_today").checked) {
            this.setCookie( "ncookie", "done" , 24 )
        }
        document.getElementById('layer_pop').style.display = "none";
    }

    setCookie( name, value, expirehours ) {
        var todayDate = new Date();
        todayDate.setHours( todayDate.getHours() + expirehours );
        document.cookie = name + "=" + escape( value ) + "; path=/; expires=" + todayDate.toGMTString() + ";"
    }


	render() {
		var { notices, faqs } = this.state
		var lang = utilService.getCurrentLang()
		return (
			<div>
				<section className="about padding-top-90 padding-bottom-90">
					<div className="container">

						<div className="row" style={{ textAlign: "center" }}>
							<div className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-12 item">
								<div className="text-public-subject">
									<img src="assets/images/logo_x2_main.png" width="70" alt={utilService.getLangByCode("msg4", "sey Chain 기술 소개")} />
									{utilService.getLangByCode("msg4", "sey Chain 기술 소개")}
								</div>
							</div>
						</div>


						<div className="container mgt_30">
							<div className="row">
								<div className="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-6 item mgt_50">
									<div className="content">
										<h3 className="smdosa">{utilService.getLangByCode("msg4-1", "가상체인")}</h3>
										<hr />
										<p className="orbs_text">
                                            가상 체인은 공유 노드 인프라 환경에서 독립적인 전용 블록체인의 역할을 수행합니다.
                                            공유 환경을 통해 보안성과 분산화의 이점을 가지면서 가상화가 제공하는 격리 효과와 사용자 정의 기능을 활용할 수 있습니다.
                                            서로 다른 가상 체인 상에서는 트랜잭션에 대한 합의 알고리즘을 독립적으로 수행할 수 있으며,
                                            각 체인이 가진 개별 리소스를 통해 동시에 실행됩니다. 가상 체인의 원장도 독립적으로 유지관리 할 수 있으며
                                            모든 계산은 병렬로 수행 됩니다. 또한 각 가상 체인이 격리되기 때문에 가상 시스템의 메모리를 절약할 수 있습니다.
										</p>
									</div>
								</div>
								<div className="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-6 item">
									<div className="img-div1">
										<img src="assets/images/orbs_01.png" alt={utilService.getLangByCode("msg4-1", "가상체인")} />
									</div>
								</div>
							</div>
						</div>



						<div className="container mgt_50">
							<div className="row">
								<div className="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-6 item">
									<div className="content">
										<h3 className="smdosa">{utilService.getLangByCode("msg4-3", "무작위 지분증명")}</h3>
										<hr />
										<p className="orbs_text">
                                            sey Chain의 합의(Consensus) 모델인 무작위 지분증명 (RPOS; Randomized Proof of Stake은 안전하고 빠릅니다.
                                            각 합의 라운드마다 설정된 다수의 검증자(Validator) 중에서 소수의 무작위 집단이 선택됩니다. 다수의 검증자가
                                            참여함으로써 보안성과 분산화가 보장되며, 소수의 합의 집단에 의한 검증 작업은 빠른 속도와 확장성을 제공합니다.
                                            노드 수가 늘어날 수록 보안성은 높아지지만 속도는 그대로 유지되며, 각각의 가상 체인 상에서 각자 필요로 하는
                                            합의 모델을 따로 선택할 수 있어 작업 속도, 공정성, 비용의 밸런스를 최적화할 수 있습니다.
										</p>
									</div>
								</div>
								<div className="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-6 item">
									<div className="img-div2">
										<img src="assets/images/orbs_05_04.png" alt={utilService.getLangByCode("msg4-3", "무작위 지분증명")} />
									</div>
								</div>
							</div>
						</div>



						<div className="container mgt_90_mo">
							<div className="row">
								<div className="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-6 item">
									<div className="content">
										<h3 className="smdosa">{utilService.getLangByCode("msg4-5", "컨소시엄 블록체인")}</h3>
										<hr />
										<p className="orbs_text">
                                            sey Chain은 컨소시엄 블록체인(Consortium Blockchain) 형태로 운영됩니다.
                                            sey Chain은 예스24가 운영하고 있는 다양한 디지털 콘텐츠 사업 영역에 적용될 뿐만 아니라
                                            향후에는 예스24와 연계된 파트너사들과 함께 운영될 예정입니다. sey Chain에 참여하는 모든
                                            파트너사들은 빠른 처리 속도와 효율성이라는 프라이빗 블록체인의 장점과 보안성과 독립성 강화라는
                                            퍼블릭 블록체인의 장점을 동시에 보장받게 될 것입니다.
										</p>
									</div>
								</div>
								<div className="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-6 item">
									<div className="img-div3">
										<img src="assets/images/orbs_07.png" alt={utilService.getLangByCode("msg4-5", "컨소시엄 블록체인")} />
									</div>
								</div>
							</div>
						</div>




						<div className="row" style={{ textAlign: "center" }}>
							<div className="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-12 item">
								<a href={this.state.md.mobile() ? this.state.md.mobile() == "iPhone" || this.state.md.mobile() == "iPad" ? "https://itunes.apple.com/kr/app/%EC%84%B8%EC%9D%B4%EC%9B%94%EB%A0%9B/id1434372636?mt=8" : "https://play.google.com/store/apps/details?id=com.yes24.seywallet" : "javascript:void(window.open('popup.html','win0','width=510,height=180,top=250,left=300,status=no,toolbar=no,scrollbars=no'))"} className="btn btn-md u-btn-cyan" >{utilService.getLangByCode("label.walletdownload", "지갑앱 다운로드")}</a>
							</div>
						</div>
					</div>
				</section>



				<section className="blog padding-top-90 padding-bottom-90 primary_bg2">
					<div className="container">

						<div className="row">

							<div className="fo28">sey Chain Ecosystem</div>

							<div className="col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-12 item">

								<div className="pc_view">
									<div className="tac padding-bottom-90">
										<img src="assets/images/yes_010.png" alt={utilService.getLangByCode("msg5-1", "sey CHAIN 에코시스템")} />
									</div>

									<div className="dapp_text_01">
										<h4>YES24 DAPPs</h4>
										<hr />
										<p>현재 웹툰/웹소설 플랫폼인<br />
                                            스토리24(Story24)에서<br />
                                            sey coin을 획득할 수 있으며,<br />
                                            향후 다양한 사업 영역에<br />
                                            확대 적용할 예정입니다.</p>
									</div>
									<div className="dapp_text_02">
										<h4>Partner DAPPs</h4>
										<hr />
										<p>sey CHAIN은 다양한<br />
                                            비즈니스 영역에서 활동하는<br />
                                            파트너사들의 참여를 확대할<br />
                                            예정입니다.</p>
									</div>
									<div className="dapp_text_03">
										<h4>Exchange</h4>
										<hr />
										<p>sey Coin은 전용 Mall에서 다양한 상품으로 교환이 가능합니다.</p>
									</div>
								</div>



								<div className="mo_view">
									<div className="tac padding-bottom-90">
										<img src="assets/images/yes_010_mo.png" alt={utilService.getLangByCode("msg5-1", "sey CHAIN 에코시스템")} />
									</div>


									<div className="dapp_text_01">
										<h4>YES24 DAPPs</h4>
										<hr />
										<p>현재 웹툰/웹소설 플랫폼인
                                            스토리24(Story24)에서
                                            sey coin을 획득할 수 있으며,
                                            향후 다양한 사업 영역에
                                            확대 적용할 예정입니다.</p>
									</div>
									<div className="dapp_text_02 mgt_50">
										<h4>Partner DAPPs</h4>
										<hr />
										<p>sey CHAIN은 다양한
                                            비즈니스 영역에서 활동하는
                                            파트너사들의 참여를 확대할
                                            예정입니다.</p>
									</div>
									<div className="dapp_text_03 mgt_50">
										<h4>Exchange</h4>
										<hr />
										<p>sey Coin은 전용 Mall에서 다양한 상품으로 교환이 가능합니다.</p>
									</div>
								</div>

							</div>
						</div>
					</div>
				</section>






				<section className="blog padding-top-90 padding-bottom-90 primary_bg">
					<div className="container">
						<div className="row">

							<div data-kui-anim="bounce" className="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-6 item">

								<div className="content">
									<Link to={"/notice"}><h3>NOTICE</h3></Link>
									<hr />
									{
										notices.map((notice, index) => (
											<div key={index} style={{ cursor: "pointer" }} onClick={() => {
												this.context.router.history.push("/noticeDetail?seq=" + notice.seq)
											}}><p>{lang == "ko" ? notice.subject : notice.subject_en}</p></div>
										))
									}
								</div>
							</div>

							<div data-kui-anim="bounce" className="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 col-md-offset-0 col-md-6 item">

								<div className="content">
									<Link to={"/faq"}><h3>FAQ</h3></Link>
									<hr />
									{
										faqs.map((faq, index) => (
											<div key={index} style={{ cursor: "pointer" }} onClick={() => {
												this.context.router.history.push("/faqDetail?seq=" + faq.seq)
											}}><p>{lang == "ko" ? faq.quest_note : faq.quest_note_en}</p></div>
										))
									}
								</div>
							</div>
						</div>
					</div>
					<div className="container text-center mgt_50" ref="img2">
						<a href="http://story24.yes24.com/" target="_blank"><img src="assets/images/logo_bottom_01.png" onMouseOver={(e) => { e.target.src = "assets/images/logo_bottom_01_c.png" }} onMouseOut={(e) => { e.target.src = "assets/images/logo_bottom_01.png" }} alt="스토리24 로고" /></a>
						<a href="http://www.yes24.com/main/default.aspx" target="_blank"><img src="assets/images/logo_bottom_02.png" onMouseOver={(e) => { e.target.src = "assets/images/logo_bottom_02_c.png" }} onMouseOut={(e) => { e.target.src = "assets/images/logo_bottom_02.png" }} alt="YES24 로고" /></a>
						<a href="http://seymall.yes24.com/" target="_blank"><img src="assets/images/logo_bottom_03.png" onMouseOver={(e) => { e.target.src = "assets/images/logo_bottom_03_c.png" }} onMouseOut={(e) => { e.target.src = "assets/images/logo_bottom_03.png" }} alt="코인몰 로고" /></a>
					</div>
				</section>

				{/* popup 영역 시작 */}
				{
					utilService.isMaintenancePopup() ? 
						<div class="layer_popup" id="layer_pop" style={{display: this.state.popupDisplay ? 'inline' : 'none'}}>
							<table border="0" cellpadding="0" cellspacing="0">
								<tr>
									{/* <td><a href="https://ai-challenge.kr/" target="_blank"><img src="assets/images/popup.png" border="0" /></a></td> */}
									<td><img src="assets/images/popup.png" border="0" /></td>
								</tr>
								<tr>
									<td align="center" height="30" bgcolor="#333333">
										<table width="95%" border="0" cellpadding="0" cellspacing="0">
											<tr style={{ cursor: "#fff" }}>
												<td align="left" class="pop"><input type="checkbox" name="pop_today" id="pop_today" /> 오늘 하루 이 창 열지 않음</td>
												<td align="right" class="pop"><a onClick={this.closeWin}>닫기</a></td>
											</tr>
										</table>
									</td>
								</tr>
							</table>
						</div>      
					: <></>
				}

                {/* popup 영역 끝 */}

			</div>
		)
	}
}

HomePage.contextTypes = {
	router: PropTypes.object.isRequired
}
export default HomePage
