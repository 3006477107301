import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

//import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'react-router-redux';

//import spreeStore from '../store';
import history from '../browser-history';
import configRoutes from '../routes';
//import ConnectedIntlProvider from '../connected-intl-provider';

//import logo from '../logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
        <BrowserRouter basename={''}>
            { configRoutes() }
        </BrowserRouter>
    );
  }
}

export default App;
