import React, { Component } from 'react';
import { Link } from "react-router-dom"
import { Pagination } from 'react-bootstrap'
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'
import pubsub from 'pubsub-js';

import apiService from "../../apis/apiService"
import utilService from "../../common/utilService"

class UsagePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usages: [],
            startDate: moment(),
            toDate: moment(),
            activePage: 1,
            numOfPages: 1,
            totalNumberOfRecords: 0,
            user: localStorage.user ? JSON.parse(localStorage.user) : null,
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0
        var user = localStorage.user ? JSON.parse(localStorage.user) : null
        if (!user || !user.user_id) {
            this.context.router.history.replace('/login');
            return
        }
        this.searchPage(1)
        pubsub.publish('refreshUserInfo');
    };

    searchPage(page) {
        // console.log(page)
        var { startDate, toDate } = this.state
        // console.log(startDate.format('YYYY-MM-DD') + ' 00:00:00')
        // console.log(toDate.format('YYYY-MM-DD') + ' 00:00:00')
        apiService.getUsages(startDate.format('YYYY-MM-DD') + ' 00:00:00', toDate.format('YYYY-MM-DD') + ' 23:59:59', page, 5, (err, rets) => {
            // console.log('getTransfers', err, rets)
            if (!err) {
                this.setState({
                    activePage: page,
                    usages: rets.rows,
                    numOfPages: Math.ceil(rets.count / 5),
                    totalNumberOfRecords: rets.count
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
    }
    changeStartDate(startDate) {
        this.setState({
            startDate
        })
    }

    changeEndDate(toDate) {
        this.setState({
            toDate
        })
    }

    setPeriod(days) {
        var startDate = moment().add(days * (-1), 'days');
        var toDate = moment()
        this.setState({
            startDate,
            toDate
        })
    }

    handlePageSelect(eventKey) {
        this.searchPage(eventKey)
    }

    render() {
        var { usages, user, startDate, toDate, activePage, numOfPages, totalNumberOfRecords } = this.state
        return (
            <div>
                <section className="contact padding-top-90 padding-bottom-90 primary_bg" id="contact">
                    <div className="row" style={{ maxWidth: 1220 }}>
                        <div className="col-md-12">
                            <div className="kv_csr" id="section_csr">
                                <div className="row">
                                    <h2 className="stt01"><i className="fa fa-quote-left" style={{ marginRight: 5 }}></i>{utilService.getLangByCode('label.usagehistory', '사용내역')}</h2>
                                    <p className="stxt01">{utilService.getLangByCode('msg13', '코인 사용 내역을 기간별로 조회할 수 있습니다.')}</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="p-2">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="input-group date fl">
                                                <DatePicker selected={startDate} maxDate={toDate} onChange={this.changeStartDate.bind(this)} className='form-control2 fl' />
                                            </div>
                                            <span className="fl" style={{ marginLeft: 5, marginRight: 5 }}>~</span>
                                            <div className="input-group date fl">
                                                <DatePicker selected={toDate} minDate={startDate} onChange={this.changeEndDate.bind(this)} className='form-control2 fl' />
                                            </div>
                                            <div className="modiv">
                                                <button type="button" className="btn btn-primary ml10" onClick={this.searchPage.bind(this, 1)}>{utilService.getLangByCode('label.inquiry', '조회')}</button>
                                                <button type="button" className="btn btn-light ml5" onClick={this.setPeriod.bind(this, 7)}>{utilService.getLangByCode('label.7days', '7일')}</button>
                                                <button type="button" className="btn btn-light ml5" onClick={this.setPeriod.bind(this, 30)}>{utilService.getLangByCode('label.30days', '30일')}</button>
                                                <button type="button" className="btn btn-light ml5" onClick={this.setPeriod.bind(this, 90)}>{utilService.getLangByCode('label.90days', '90일')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="usage_table">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <colgroup>
                                                <col width="10%" />
                                                <col width="20%" />
                                                <col width="13%" />
                                                <col width="45%" />
                                                <col width="12%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>{utilService.getLangByCode('label.usagedate', '사용일시')}</th>
                                                    <th>{utilService.getLangByCode('label.site', '사이트')}</th>
                                                    <th>{utilService.getLangByCode('label.event', '이벤트')}</th>
                                                    <th>{utilService.getLangByCode('msg14', '코인수량')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    usages.map((t, idx) => {
                                                        return (<tr key={idx}>
                                                            <td>{idx + 1}</td>
                                                            <td>{utilService.getDateTime2ByFormat(t.reg_dt, 'YYYY-MM-DD HH:mm')}</td>
                                                            <td>{t.site_cd}</td>
                                                            <td>{t.event}</td>
                                                            <td>{t.token}</td>
                                                        </tr>)
                                                    })
                                                }
                                                {
                                                    usages.length == 0 && <tr>
                                                        <td colSpan={5}>{utilService.getLangByCode('msg18', '사용내역이 없습니다.')}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <footer className="table_footer_background bdt">
                                    <div className="row">
                                        <div className="col-sm-4">
                                        </div>
                                        <div className="col-sm-4" style={{ textAlign: 'center' }}>
                                            <Pagination
                                                className={totalNumberOfRecords === 0 ? 'hidden' : 'shown'}
                                                prev
                                                next
                                                first
                                                last
                                                ellipsis
                                                maxButtons={5}
                                                items={numOfPages}
                                                activePage={activePage}
                                                onSelect={this.handlePageSelect.bind(this)}>
                                            </Pagination>
                                        </div>
                                        <div className="col-sm-4">
                                        </div>
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };
};
UsagePage.contextTypes = {
    router: PropTypes.object.isRequired
}
export default UsagePage;
