import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col, Panel, Button } from 'react-bootstrap';
import { Router, Route, Link, History } from 'react-router-dom';

class Error404 extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            error: false,
            success: false
        }
        this.formInstance = null;
    }

    render() {

        var textCentetStyle = {
            backgroundColor: 'white'
        }

        return (
            <div className="abs-center wd-xl panel panel-flat">
                <div className="text-center mb-xl">
                    <div className="text-lg mb-lg">404</div>
                    <p className="lead m0">We couldn't find this page.</p>
                    <p>The page you are looking for does not exists.</p>
                </div>
                <ul className="list-inline text-center text-sm mb-xl">
                    <li><a href="javascript:;" className="text-muted" onClick={() => { this.context.router.history.goBack() }}>Go back</a></li>
                    <li className="text-muted">|</li>
                    <li><Link to="/" className="text-muted">Go home</Link></li>
                </ul>
            </div>
        );
    }
}

Error404.contextTypes = {
    router: PropTypes.object.isRequired
}

export default Error404;
