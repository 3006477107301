import React, { Component } from 'react';
import {Link} from "react-router-dom"
import utilService from "../../common/utilService"

class PolicyPage extends Component {

    componentDidMount() {
        document.documentElement.scrollTop = 0
    };

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        var user = localStorage.user?JSON.parse(localStorage.user):null
        var isLogin = user && user.user_id
        return (
            <div>
                <div id="sub_menu" className="sub_menu">
                    <div className="row">
                        {!isLogin && <ul className="clearfix col3">
                            <li><Link to={'/login'}>{utilService.getLangByCode('label.login', '로그인')}</Link></li>
                            <li className='act'><Link to={'/policy'}>{utilService.getLangByCode('label.terms', '이용약관')}</Link></li>
                            <li ><Link to={'/privacy'}>{utilService.getLangByCode('label.privacy', '개인정보처리방침')}</Link></li>
                        </ul>}
                        {isLogin && <ul className="clearfix col2">
                            <li className='act'><Link to={'/policy'}>{utilService.getLangByCode('label.terms', '이용약관')}</Link></li>
                            <li ><Link to={'/privacy'}>{utilService.getLangByCode('label.privacy', '개인정보처리방침')}</Link></li>
                        </ul>}
                    </div>
                </div>
                <div id="contents">
                    <section className="policy clearfix">
                        <h4>제 1장 총 칙</h4>
                        <h5>제1조(목적)</h5>

                        <p>이 약관은 예스이십사 주식회사가 운영하는 인터넷사이트(http://www.yes24.com) 에서 제공하는 전자상거래 관련 서비스를 이용함에 있어 회사와 이용자의 권리, 의무, 책임사항 및 서비스 이용절차 등에 관한 사항을 규정함을 목적으로 합니다. 회사가 운영하는 오프라인의 영업장과 관련된 전자상거래 관련 서비스에 대해서도 그 성질에 반하지 아니하는 한 이 약관을 준용합니다.</p>

                        <h5>제2조(용어의 정의)</h5>
                        <p>① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>

                        <ul>
                            <li>1. 회사 : 예스이십사 주식회사를 의미하며, 이와 아울러 예스이십사 주식회사가 재화 또는 용역(이하 ‘재화 등’이라 합니다)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장(www.yes24.com)을 말하고, 또한 사이버 몰을 운영하는 사업자의 의미로도 사용합니다.</li>
                            <li>2. 이용자 : 이 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
                            <li>3. 회원 : 회사에 개인정보를 제공하여 회원등록을 한 자로서, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자로서 아래와 같이 구분될 수 있습니다.</li>
                            <ul>
                                <li>가.일반회원 : 상품구매 및 구매와 관련하여 제공되는 서비스를 이용할 수 있는 만14세 이상의 일반회원</li>
                                <li>나.어린이회원 : 보호자의 동의를 얻어 가입한 만14세 미만의 회원</li>
                                <li>다.법인회원 : 사업자 정보로 가입한 회원</li>
                                <li>라.외국인회원 : 외국인등록번호로 가입한 회원</li>
                                <li>마.해외거주회원 : 해외거주 주소로 가입한 회원</li>
                            </ul>
                            <li>4. 비회원 : 회원으로 가입하지 않고 서비스를 이용하는 자를 말합니다.</li>
                            <li>5. 판매자 : 회사에 입점하여 자율적으로 상품 등록, 가격 책정, 배송 서비스 등을 하는 자 또는 중고샵에 중고상품을 판매하는 자를 말합니다.</li>
                            <li>6. 디지털상품 : 동영상, eBook, e러닝, 시프트북스 및 음원과 같이 VOD, 스트리밍, 다운로드 등의 형태로 제공되는 무배송 상품을 말합니다.</li>
                            <li>7. YES포인트 : 회원이 상품을 구매하거나 용역을 이용하고 받을 수 있는 것으로 회사가 발행, 관리하며 YES머니로 전환될 수 있는 마일리지를 말합니다.</li>
                            <li>8. 머니 : 상품을 구매하거나 용역을 이용할 수 있는 선불식 전자지급수단이나, YES머니를 말하며아래와 같이 구분될 수 있습니다.</li>
                            <li>가.YES머니 : YES포인트가 회사가 정한 기준에 따라 전환된 것</li>
                            <li>나.디지털머니 : 디지털상품을 결제할 때, 현금처럼 사용할 수 있는 사이버머니</li>
                            <li>다.크레마머니 : 크레마 기기를 구매하거나 이벤트를 통해 제공 받을 수 있으며, 디지털 상품 구매 시 선 차감할 수 있는 멤버쉽 포인트</li>
                            <li>라.캐시 : 디지털상품 중 시프트북스를 구매할 때, 현금처럼 사용 할 수 있는 사이버머니</li>
                            <li>9. 예치금 : 결제대금 환불, 중고샵 상품판매 정산, 초과 입금차액 환불 등으로 발생한 것으로 상품 구매와 계좌환불이 가능한 현금성금액</li>
                            <li>10. 상품권 : 회사가 온,오프라인으로 발행하여 판매하는 것으로 유효기간 내에 재화나 용역 구매 시 현금처럼 자유롭게 사용 가능한 결제수단</li>
                            <li>② 제1항에 정의되지 않은 용어는 일반적인 상관례에 따릅니다.</li>
                        </ul>


                        <h5>제3조(약관의 명시와 개정)</h5>
                        <ul>
                            <li>① 회사는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 알 수 있도록 회사의 서비스가 제공되는 인터넷 사이트(http://www.yes24.com) 첫 화면에 게시합니다.</li>
                            <li>② 회사는 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법, 정보통신망 이용촉진 및 정보보호등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</li>
                            <li>③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 회사의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 단, 이용자에게 불리한 약관을 개정할 때에는 적용일자 30일 이전부터 공지하고 전자우편, 로그인시 동의창 등의 전자적 수단을 통해 명확히 통지합니다.</li>
                            <li>④ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제등에 관한 법률, 공정거래위원회가 정하는 전자상거래등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다.</li>
                            <li>⑤ 약관에 동의하는 것은 회사가 운영하는 인터넷 사이트를 방문하여 약관의 개정사항을 확인하는 것에 동의함을 의미하며, 변경된 약관에 대한 정보를 알지 못하여 발생하는 이용자의 피해에 대해 회사는 책임을 지지 않습니다.</li>
                            <li>⑥ 개정된 약관에 동의하지 않는 회원은 회원 탈퇴(계약의 해지)를 요청할 수 있고, 약관 적용일 까지 거부의 의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주 합니다.</li>
                        </ul>


                        <h4>제2장 회사의 서비스</h4>

                        <h5>제4조(서비스의 제공 및 변경)</h5>

                        <ul>
                            <li>① 회사는 다음과 같은 업무를 수행합니다.</li>
                            <ul>
                                <li>1. 재화 등에 대한 정보 제공 및 구매계약의 체결</li>
                                <li>2. 구매계약이 체결된 재화 등의 배송</li>
                                <li>3. 기타 회사가 정하는 업무</li>
                            </ul>

                            <li>② 회사는 재화 등의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 등의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 등의 내용 및 제공일자를 명시하여 현재의 재화 등의 내용을 게시한 곳에 그 제공일자 이전 7일부터 공지합니다. 단, 회사가 합리적으로 예측할 수 없는 불가피한 여건이나 사정이 있는 경우, 위 공지를 하지 않을 수 있습니다.</li>
                            <li>③ 회사가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 회사는 이로 인하여 이용자가 입은 손해를 배상합니다. 단, 회사에 고의 또는 과실이 있는 경우에 한합니다.</li>
                        </ul>


                        <h5>제5조(서비스의 중단)</h5>
                        <ul>

                            <li>① 회사는 컴퓨터 등 정보통신설비의 보수점검.교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</li>
                            <li>② 제1항에 의한 서비스 중단의 경우에는 회사는 제8조에 정한 방법으로 이용자에게 통지합니다. 다만 회사가 합리적으로 예측할 수 없는 사유로 인한 서비스 중단의 경우에는 사전통지를 할 수 없으며 사후에 이를 공지합니다.</li>
                            <li>③ 회사는 제1항 및 제2항 단서조항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, 회사에 고의 또는 과실이 있는 경우에 한합니다.</li>
                            <li>④ 사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 제8조에 정한 방법으로 이용자에게 통지하고 당초 회사에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, 회사가 보상기준 등을 고지하지 아니한 경우에는 이용자들의 적립금 등을 회사에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.</li>
                        </ul>


                        <h4>제3장 회원관리</h4>

                        <h5>제6조(가입신청, 거부)</h5>
                        <ul>
                            <li>① 이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.</li>
                            <li>② 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.</li>

                            <ul>
                                <li>1. 가입신청자가 이 약관 제7조 제4항에 의하여 이전에 회원자격을 상실한 적이 있는 경우</li>
                                <li>(단, 제7조 제4항에 의한 회원자격 상실 후 3년이 경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우에는 예외)</li>
                                <li>2. 신청, 등록 내용에 허위, 기재누락, 오기 등이 있는 경우</li>
                                <li>3. 만 14세가 되지 않은 자가 신청하였을 경우</li>
                                <li>(단, 법정대리인의 동의가 있는 경우에는 회원가입 가능)</li>
                                <li>4. 이미 가입된 회원과 이름 및 CI(본인확인기관의 개인식별정보), DI(중복가입 확인정보) 또는 사업자등록번호, 법인등록번호가 동일한 경우</li>
                                <li>5. 회원 탈퇴한 날로부터 1개월 이내에 재 가입신청을 하는 경우</li>
                                <li>6. 이 약관에 위배되거나 부당한 이용 신청임이 확인되는 경우</li>
                                <li>7. 회사의 판매 이용약관의 적용을 받는 자가 해당 약관을 위반한 경우</li>
                                <li>8. 기타 회원으로 등록하는 것이 회사의 기술상 현저하게 지장이 있다고 판단되는 경우</li>
                            </ul>
                            <li>③ 회원가입계약의 성립시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.</li>
                            <li>④ 가입신청자가 회사에 회원으로 가입하더라도 로그인하지 않은 상태에서 주문하는 경우는 회원주문이 아니라 비회원주문으로 인정합니다.</li>
                            <li>⑤ 회원은 제15조 제1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 등 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다. 이러한 변경사항의 미 통보로 인해 발생하는 손해는 회원에게 그 책임이 있습니다.</li>
                        </ul>


                        <h5>제7조(회원 탈퇴 및 자격 상실 등)</h5>
                        <ul>
                            <li>① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴요청을 처리합니다. 다만 재화 등의 거래가 완료되지 아니한 경우에는 거래를 완료하여야 하며 거래를 철회하거나 취소하는 경우에는 그로 인한 불이익은 회원이 부담하여야 하고, 회원의 회사에 대한 채무(마이너스 머니, 마이너스 포인트 등 포함)의 이행이 완료되지 아니한 경우에는 완료될 때까지 회원탈퇴가 제한될 수 있습니다.</li>
                            <li>② 회원은 정보제공에 동의한 이후에도 회사가 향후에 패밀리 사이트에 회원 정보를 제공하는 것을 원하지 않을 경우 언제라도 동의철회를 요청할 수 있습니다.</li>
                            <li>③ 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지할 수 있습니다.</li>
                            <ul>
                                <li>1. 가입 시에 허위 내용을 등록하거나 기타 제6조의 신청거부 사유가 있었음이 밝혀진 경우</li>
                                <li>2. 회사를 이용하여 구입한 재화.용역 등의 대금, 기타 회사 이용에 관련하여 회원이 부담하는 채무를 기일 내에 지급하지 않는 경우</li>
                                <li>3. 다른 사람의 회사 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우</li>
                                <li>4. 회사를 이용하여 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                                <li>5. 기타 다음과 같은 행위 등을 반복하여 회사의 운영을 방해하는 행위를 하는 경우</li>
                                <ul>
                                    <li>가.회사의 운영에 관련하여 근거 없는 사실 또는 허위의 사실을 적시하거나 유포하여 회사의 명예를 실추시키거나 회사의 신뢰성을 해하는 경우</li>
                                    <li>나.회사의 이용과정에서 회사의 직원에게 폭언 또는 음란한 언행을 하여 업무환경을 심각히 해하는 경우</li>
                                    <li>다.회사의 이용과정에서 이유 없는 잦은 연락이나 소란 또는 협박, 인과관계가 입증되지 않는 피해에 대한 보상 (적립금, 현금, 상품 등) 요구 등으로 업무를 방해하는 경우</li>
                                    <li>라.회사를 통해 구입한 재화 또는 용역에 특별한 하자가 없는데도 불구하고 일부 사용 후 상습적으로 전부 또는 일부를 반품하는 등 업무를 방해하는 경우</li>
                                </ul>
                            </ul>
                            <li>④ 회사가 회원 자격을 제한.정지한 후, 동일한 행위를 2회 이상 반복하거나 30일 이내에 그 사유를 시정하지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다.</li>
                            <li>⑤ 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.</li>
                        </ul>

                        <h5>제8조(회원에 대한 통지)</h5>
                        <ul>
                            <li>① 회사가 회원에 대한 통지를 하는 경우, 회원이 회사에 제출한 전자우편 주소로 할 수 있습니다. 회원이 통지를 받을 전자우편 주소를 지정한 때에는 회사의 통지는 부가통신사업자 또는 수신인이 관리하는 메일서버 등에 도착하여 회원이 검색할 수 있는 상태에 이르렀을 때 도달된 것으로 보며, 회원이 전자우편을 개봉하였는지 여부, 회원의 PC에 전자우편이 전송되었는지 여부는 불문합니다.</li>
                            <li>② 회사는 불특정다수 회원에 대한 통지의 경우 1주일 이상 회사 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다.</li>
                        </ul>


                        <h4>제4장 구매계약</h4>
                        <h5>제9조(구매 및 이용신청)</h5>
                        <p>이용자는 회사에서 이하의 방법에 의하여 구매를 신청합니다.</p>
                        <ul>
                            <li>1. 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</li>
                            <li>2. 재화 또는 용역의 선택</li>
                            <li>3. 결제방법의 선택</li>
                            <li>4. 본 약관에 동의한다는 표시(예 : 마우스 클릭)</li>
                        </ul>


                        <h5>제10조(계약의 성립)</h5>
                        <ul>
                            <li>①회사는 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하지 않는 한 승낙합니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지합니다.</li>
                            <ul>
                                <li>1. 신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                                <li>2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우</li>
                                <li>3. 기타 구매신청에 승낙하는 것이 회사 기술상 현저하게 지장이 있다고 판단하는 경우</li>
                            </ul>
                            <li>② 회사의 승낙이 제12조 제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.</li>
                        </ul>


                        <h4>제5장 대금결제</h4>
                        <h5>제11조(지급방법과 시기)</h5>
                        <p>회사에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 하나로 할 수 있습니다. 단, 회사에서 현재 서비스 중인 것에 한합니다.</p>
                        <ul>
                            <li>1. 폰뱅킹, 인터넷뱅킹, 메일뱅킹 등의 각종 계좌이체</li>
                            <li>2. 선불카드, 직불카드, 신용카드 등의 각종 카드결제</li>
                            <li>3. 온라인무통장입금</li>
                            <li>4. 전자화폐에 의한 결제</li>
                            <li>5. 머니와 예치금에 의한 결제</li>
                            <li>6. 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제</li>
                            <li>7. 기타 회사에서 정하는 통상적인 방법의 대금결제 등</li>
                        </ul>
                        <h5>제12조(수신확인통지.구매신청 변경 및 취소)</h5>

                        <ul>
                            <li>① 회사는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.</li>
                            <li>② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있습니다.</li>
                            <li>③ 회사는 배송 전 이용자의 구매신청 변경 및 취소 요청이 있는 때에는 지체 없이 그 요청에 따라 처리합니다.</li>
                            <li>④ 회사는 이용자가 구매(주문)신청 후 제11조에 의한 지급방법으로 그 신청일로부터 2주(14일) 이내 상품대금에 대해 입금이 확인되지 않을 경우 회사는 별도 의사 표시없이 구매계약을 해제하는 것으로 처리할 수 있습니다.</li>
                        </ul>
                        <h4>제6장 배송, 주문취소 및 환불</h4>
                        <h5>제13조(배송)</h5>
                        <p>회사는 이용자가 구매한 재화 등에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 회사의 고의.과실로 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상합니다. 단, 공휴일과 기타 휴무일 또는 날씨 및 택배사 등의 불가항력적 사유가 발생하는 경우 그 해당기간은 배송소요기간에서 제외됩니다.</p>


                        <h5>제14조(반품, 교환, 환불, 취소)</h5>

                        <ul>
                            <li>① '전자상거래 등에서의 소비자보호에 관한 법률' 제17조에 따라 이용자는 상품을 배송받은 날로부터 7일 이내에 반품 또는 교환을 요청할 수 있습니다. 단, 다음 각 호의 경우에는 이용자가 반품이나 교환을 요청할 수 없습니다.</li>
                            <ul>
                                <li>1. 이용자의 책임이 있는 사유로 재화 등이 멸실되거나 훼손된 경우</li>
                                <li>2. 이용자의 사용 또는 일부 소비로 재화 등의 가치가 현저히 감소한 경우</li>
                                <li>3. 복제가 가능한 재화 등의 포장을 개봉하거나 훼손한 경우</li>
                                <li>4. 기타 재화 등의 특성상 환불이나 교환을 요청할 수 없는 합리적인 사유가 있는 경우</li>
                            </ul>
                            <li>② 제1항에도 불구하고 다음 각 호의 경우에 이용자는 재화 등의 수령일부터 30일 이내에 환급, 반품 및 교환을 요구할 수 있고, 반품 비용 일체를 회사 또는 판매자가 부담합니다.</li>
                            <ul>
                                <li>1. 재화 등의 내용이 표시,광고 내용과 다르거나 계약내용과 다르게 이행된 경우. 다만 이경우에는 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내 (늦어도 수령일로 부터 3개월 이내) 청약철회를 할 수 있습니다.</li>
                                <li>2. 배송된 재화가 이용자의 과실 없이 파손, 손상되었거나 오염되었을 경우</li>
                                <li>3. 재화가 광고에 표시된 배송기간보다 늦게 배송된 경우</li>
                            </ul>
                            <li>③ 제2항에 해당하지 않는 사유로 배송된 재화를 반환하는 경우 반품 비용 및 발송비 일체를 이용자가 부담해야 합니다.</li>
                            <li>④ 이용자가 개봉한 소프트웨어 및 음반, 만화책 및 단시간 내에 필독이 가능한 잡지, 영상 화보집의 경우는 제2항 각 호에 해당하지 않는 경우 반품 및 환불이 불가합니다.</li>
                            <li>⑤ 이용자와 연락이 되지 않을 경우 모든 환불금액은 본인확인의 안전성을 위하여 구매대금 중 적립금 사용분을 제외한 실제 결제액의 경우 이 약관 37조의 예치금으로 환불하는 것을 원칙으로 합니다.</li>
                            <li>⑥ 제2항에 해당되지 않는 사유로 반품하여 주문을 취소하고 해당 주문으로 발생한 적립금을 이미 사용하였을 경우, 사용한 적립금액을 차감한 후 환불함을 원칙으로 합니다. 다만 환불순서는 이용자의 적립금 잔액, 예치금 등으로 합니다.</li>
                            <li>⑦ 거래가 취소되어 결제대금을 환불할 경우는 회사는 거래가 취소된 날로부터 2영업일 이내에 이용자에게 환불에 필요한 조치를 합니다. 신용카드로 결제했을 때는 환불을 요청한 즉시 결제 승인이 취소되고, 선불전자지급수단으로 결제한 거래가 취소되었을 때에는 즉시 환불조치를 합니다.</li>
                            <li>⑧ 카드결제로 구매한 건의 환불은 카드결제 취소를 통해서만 가능하며, 어떠한 경우에도 현금으로 환불되지 않습니다.</li>
                            <li>⑨ 상품이 발송되기 전까지 구매를 취소할 수 있고, 배송 중에는 반품절차에 의해 처리됩니다.</li>
                            <li>⑩ 배송준비중 상태에서는 즉시 취소처리가 완료되는 것이 원칙이나 이미 상품이 발송되었다면 발송된 상품의 왕복 배송비는 구매자가 부담해야 합니다.</li>
                        </ul>
                        <h4>제7장 회사와 이용자의 의무사항</h4>
                        <h5>제15조(개인정보보호)</h5>
                        <ul>
                            <li>①회사는 이용자의 개인정보를 보호하기 위하여 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 등 관계법령에서 정하는 바를 준수합니다.</li>
                            <li>②회사는 이용자의 정보수집 시 구매계약 이행에 필요한 최소한의 정보를 수집합니다.</li>
                            <li>다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.</li>
                            <ul>
                                <li>1. 성명, 주소, 일반전화번호(또는 휴대전화번호), 이메일</li>
                                <li>2. (회원의 경우) ID, 비밀번호, 외국인등록번호, 본인인증값 등</li>
                            </ul>
                            <li>③ 회사가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 이용자본인의 동의를 받습니다.</li>
                            <li>④ 제공된 개인정보는 이용자 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 회사가 집니다. 다만, 다음의 경우에는 예외로 합니다.</li>

                            <ul>
                                <li>1. 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자 정보(성명, 주소, 전화번호)를 알려주는 경우</li>
                                <li>2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 제공하는 경우</li>
                                <li>3. 이용자가 스스로 제3자 서비스 이용을 위하여 이용자 정보 일부를 제3자에게 제공한 경우</li>
                                <li>4. 재화 등의 거래에 따른 대금정산을 위하여 필요한 경우</li>
                                <li>5. 도용방지를 위하여 본인확인에 필요한 경우</li>
                                <li>6. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우</li>
                                <li>7. 영화 및 공연예매에 따른 티켓발송 또는 현장수령을 위하여 해당 업무를 수행하는 제 3자에게 최소한의 구매정보를 위탁하는 경우</li>
                            </ul>

                            <li>⑤ 회사는 제3항과 제4항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보보호책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받는 자, 제공목적 및 제공할 정보의 내용)등을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</li>
                            <li>⑥ 이용자는 기재된 회원정보가 변경되었을 경우 변경사항을 즉시 최신의 정보로 수정하여야 합니다. 수정하지 않은 정보로 인하여 발생하는 손해는 이용자가 부담하며, 회사는 이에 대하여 책임을 지지 않습니다.</li>
                            <li>⑦ 개인정보의 제공 등 취급과 관련한 내용은 이용자의 개인정보를 보호하기 위하여 ‘개인정보취급방침’에 자세히 고지하며, 약관과 별도의 동의를 받습니다.</li>
                        </ul>
                        <h5>제16조(회사의 의무)</h5>
                        <ul>
                            <li>① 회사는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화.용역을 제공하는 데 최선을 다하여야 합니다.</li>
                            <li>② 회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.</li>
                            <li>③ 회사는 상품이나 용역에 대하여 표시.광고의 공정화에 관한법률 제3조 소정의 부당한 표시.광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.</li>
                            <li>④ 회사는 서비스의 질을 향상시키고 이용자의 회원혜택 증대를 위해 “회사”가 운영하는 고객센터의 SMS, 이메일, TM을 통해 다음과 같은 사항을 알릴 수 있습니다.</li>
                            <ul>
                                <li>1. 상품 주문상황 공지</li>
                                <li>2. 서비스 변경사항 공지</li>
                                <li>3. 신규 서비스 및 신규 혜택(제휴카드 등) 제공 안내</li>
                                <li>단, 이용자가 원하지 않는 영리목적의 광고를 발송하지 않습니다.</li>
                            </ul>
                        </ul>
                        <h5>제17조(회원의 ID 및 비밀번호에 대한 의무)</h5>
                        <ul>
                            <li>① ID와 비밀번호에 관한 관리책임은 회원에게 있으며, 회원의 고의, 과실로 타인이 사용하게 되어 일어날 수 있는 금전적 손실 등 각종 손해에 대한 책임은 회원 본인에게 있습니다.</li>
                            <li>② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
                            <li>③ 회원의 주소, 전화번호, 이메일 등이 변경되었을 경우에는 사전에 통지하거나 변경해야 하며 변경하지 않아서 발생하는 사고에 대한 책임은 회원 본인에게 있습니다.</li>
                            <li>④ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.</li>
                        </ul>
                        <h5>제18조(이용자의 의무)</h5>
                        <p>이용자는 다음 행위를 하여서는 안됩니다.</p>
                        <ul>
                            <li>1. 개인정보 입력시 허위내용 등록(변경의 경우 포함)</li>
                            <li>2. 회사에 게시된 정보를 임의로 변경</li>
                            <li>3. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시</li>
                            <li>4. 회사 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                            <li>5. 회사 기타 제3자의 명예를 손상하거나 업무를 방해하는 행위</li>
                            <li>6. 외설 또는 폭력적인 메시지.화상.음성, 기타 공서양속에 반하는 정보를 회사에 공개 또는 게시하는 행위</li>
                            <li>7. 사실관계를 왜곡하는 정보제공 행위 등 기타 회사가 부적절하다고 판단하는 행위</li>
                            <li>8. 타인의 정보 도용</li>
                        </ul>
                        <h4>제8장 디지털상품 서비스</h4>
                        <h5>제19조(e러닝 서비스)</h5>
                        <ul>
                            <li>① 'e러닝 서비스' 이용시, 디지털상품의 특성상 환불 및 반품은 회사가 상품 안내 페이지 및 고객 안내 페이지를 통해 공지한 별도의 규정에 따릅니다.</li>
                            <li>② 'e러닝 서비스'는 무료서비스와 유료서비스로 구분되며, 유료서비스는 구매 후 승인된 회원 ID로만 사용이 가능하고 타인에게 사용권한을 양도하실 수 없습니다.</li>
                            <li>③ 회원이 유료서비스 이용신청을 취소(환불/반품 포함)하고자 하는 경우에는 취소사유를 명기하여 회사의 고객만족센터로 전화하거나 인터넷 1:1 문의를 통해 취소할 수 있습니다. 단, 회사는 다음 각 호에 해당하는 경우 취소신청을 승낙하지 아니 할 수 있습니다.</li>
                            <ul>
                                <li>1. 회원의 실수로 해당서비스를 이용하지 못한 경우</li>
                                <li>2. 환불 관련 비용이 잔여이용료를 초과할 경우</li>
                                <li>3. 기타 정당한 환불사유로 판명되지 않은 경우</li>
                            </ul>
                            <li>④ 회사는 부득이한 사정으로 유료서비스를 지속할 수 없을 경우 즉시 공지하고 'e러닝 서비스' 상품 환불 규정에 따라 잔여 이용요금을 환불합니다.</li>
                        </ul>
                        <h5>제20조(eBook 서비스)</h5>
                        <ul>
                            <li>① eBook은 인터넷을 통해 서비스를 제공합니다.</li>
                            <li>② 회사가 제공하는 eBook 뷰어 "소프트웨어"를 설치하여야 이용할 수 있습니다.</li>
                            <li>③ 컨텐츠는 회사가 '컨텐츠제공자'로부터 사용권한을 부여 받은 것으로 "컨텐츠 제공자" 또는 원 권리자에 모든 권리가 귀속되며 저작권 법령 또는 관련 지적 재산권 법률 및 조약에 의해 보호됩니다.</li>
                            <li>④ "컨텐츠 제공자"의 요구에 의해 일부 eBook은 해외 지역에서 구매가 제한될 수 있습니다.</li>
                            <li>⑤ 회원은 컨텐츠를 구매하기 전에 구매하려는 상품의 상세 내용과 조건을 정확하게 확인해야 합니다. 구매하려는 상품의 내용과 거래 조건을 확인하지 않고 구매하여 발생한 모든 손해의 책임은 회원 본인에게 있습니다.</li>
                            <li>⑥ eBook은 구매 후 승인된 회원ID로만 이용이 가능하고, 타ID로 사용권한을 양도하실 수 없습니다.</li>
                            <li>⑦ eBook 상품은 구매 후 다운로드 받거나 한번이라도 읽었을 경우, 이미 컨텐츠를 이용한 것으로 간주하고 주문 취소가 불가능합니다. 단, 아래의 경우에 대해서는 주문 취소 및 환불이 가능합니다.</li>
                            <ul>
                                <li>1. eBook 및 서비스를 구매하였음에도 사용자 환경으로 이용이 불가하다고 판단할 경우</li>
                                <li>2. 승인일로부터 7일이 경과하지 아니한 경우로서 컨텐츠를 다운로드하지 않거나 스트리밍 서비스를 하지 않은 경우</li>
                                <li>3. 대여, 자유, 정액, 회원제 등의 서비스 경우 구매 이후 환불 불가능하나 회사의 귀책사유로 인해 회원이 대여, 자유, 정액, 회원제 이용기간 동안 서비스를 제공받을 수 없게 되는 경우, 회사는 회원이 그 기간만큼 동일, 유사한 컨텐츠를 제공함</li>
                            </ul>
                            <li>⑧ 회사는 다음 각호에 해당하는 경우를 부정 이용행위로 봅니다.</li>
                            <ul>
                                <li>1. 동일한 ID로 6대 이상의 기기(PC, 스마트폰, 태블릿PC, 크레마 단말기 등)에서 동시 접속하여 서비스를 이용하는 경우</li>
                                <li>2. 자신의 ID 및 전자책, 이용권, 쿠폰, 디지털머니, 크레마머니 등을 타인에게 판매, 대여, 양도하는 행위 및 이를 광고하는 경우</li>
                                <li>3. eBook 이용시 상품 전부 혹은 일부를 복사, 복제 혹은 녹화하고 이를 배포하는 경우</li>
                                <li>4. eBook 저작권을 보호하기 위해 적용된 DRM을 무력화하는 경우</li>
                                <li>5. 상품을 불법적으로 다운로드 받거나 이를 타인에게 제공하는 경우</li>
                            </ul>
                            <li>⑨ 회사는 전항에 따른 부정 회원을 발견할 경우, 다음 각 호에 다른 조치를 취할 수 있습니다.</li>
                            <ul>
                                <li>1. 전자우편, SMS 등 전자통신을 통해 부정이용을 알리고, 해당 ID에 대한 서비스 이용을 정지시킬 수 있으며, 부정 취득 및 사용된 컨텐츠 및 결제수단은 회수 조치 및 배상을 요구할 수 있습니다.</li>
                                <li>2. 부정사용이 재차 발견될 경우 회사는 위반 내용 고지와 함께 필요시 법적인 대응을 할 수 있습니다. 회사는 회원에게 30일 간의 소명기간을 부여하고, 소명기간 동안 정당한 사유를 제시하지 못할 경우, 회원 등록을 말소하고 구입한 컨텐츠 및 권한을 종료할 수 있습니다.</li>
                            </ul>
                        </ul>
                        <h5>제21조(시프트북스 서비스)</h5>
                        <ul>
                            <li>① 시프트북스는 인터넷을 통해 서비스를 제공합니다.</li>
                            <li>② 컨텐츠는 회사가 '컨텐츠제공자'로부터 사용권한을 부여 받은 것으로 "컨텐츠 제공자" 또는 원 권리자에 모든 권리가 귀속되며 저작권 법령 또는 관련 지적 재산권 법률 및 조약에 의해 보호됩니다.</li>
                            <li>③ 회원은 컨텐츠를 구매하기 전에 구매하려는 컨텐츠의 조건을 정확하게 확인해야 합니다. 구매하려는 컨텐츠의 거래 조건을 확인하지 않고 구매하여 발생한 모든 손해의 책임은 회원 본인에게 있습니다.</li>
                            <li>④ 컨텐츠 중 이용기간이 별도로 부여된 경우 이용기간을 준수해야 합니다.</li>
                            <li>⑤ 시프트북스 컨텐츠 구매 후 승인된 회원ID로만 이용이 가능하고, 타ID로 사용권한을 양도하실 수 없습니다.</li>
                            <li>⑥ 결제 취소 및 환불 안내는 아래와 같으며 자세한 사항은 고객센터를 통해 문의 주시기 바랍니다.</li>
                            <ul>
                                <li>1. 미사용 ‘캐시’ 환불의 경우 결제된 수단으로 환불</li>
                                <li>2. 일부 사용 ‘캐시’ 환불의 경우 보너스 충전 캐시을 제외한 금액을 예치금 환불 (현금 환불 불가)</li>
                            </ul>
                            <li>⑦ 시프트북스 컨텐츠를 구매하면 이미 컨텐츠를 이용한 것으로 간주하고 주문 취소가 불가능합니다. 단, 아래의 경우에 대해서는 주문 취소 및 환불이 가능합니다.
                                <ul>
                                    <li>1. 컨텐츠를 구매하였음에도 사용자 환경으로 이용이 불가하다고 판단할 경우</li>
                                </ul>
                                <ul>
                                <li>⑧ VIP클럽 서비스 경우 1일, 3일, 7일, 30일 이용권을 구매하여 해당 기간 동안만 이용하는 서비스이며 구매 이후 VIP클럽으로 제공된 컨텐츠를 이용시는 환불 불가능하나 회사의 귀책사유로 인해 회원이 이용기간 동안 서비스를 제공받을 수 없게 되는 경우, 회사는 회원이 그 기간만큼 동일하게 이용할 수 있게 제공합니다.</li>
                                <li>⑨ 회사는 다음 각호에 해당하는 경우를 부정 이용행위로 봅니다.</li>
                                </ul>
                                <ul>
                                    <li>1. 자신의 ID 및 캐시, VIP클럽, 쿠폰 등을 타인에게 판매, 대여, 양도하는 행위 및 이를 광고하는 경우</li>
                                    <li>2. 컨텐츠의 전부 혹은 일부를 복사, 복제 혹은 녹화하고 이를 배포하는 경우</li>
                                    <li>3. 저작권을 보호하기 위해 적용된 DRM을 무력화하는 경우</li>
                                    <li>4. 컨텐츠를 불법적으로 다운로드 받거나 이를 타인에게 제공하는 경우</li>
                                </ul>
                                <ul>
                                <li>⑩ 회사는 전항에 따른 부정 회원을 발견할 경우, 다음 각 호에 다른 조치를 취할 수 있습니다.</li>
                                </ul>
                                <ul>
                                    <li>1. 전자우편, SMS 등 전자통신을 통해 부정이용을 알리고, 해당 ID에 대한 서비스 이용을 정지시킬 수 있으며, 부정 취득 및 사용된 컨텐츠 및 결제수단은 회수 조치 및 배상을 요구할 수 있습니다.</li>
                                    <li>2. 부정사용이 재차 발견될 경우 회사는 위반 내용 고지와 함께 필요시 법적인 대응을 할 수 있습니다. 회사는 회원에게 30일 간의 소명기간을 부여하고, 소명기간 동안 정당한 사유를 제시하지 못할 경우, 회원 등록을 말소하고 구입한 컨텐츠 및 권한을 종료할 수 있습니다.</li>
                                </ul>
                            </li>
                        </ul>
                        <h4>제9장 영화 서비스</h4>
                        <h5>제22조 (영화예매)</h5>
                        <ul>
                            <li>① 영화 예매는 인터넷을 통해 서비스를 제공합니다.</li>
                            <li>② 회사는 예매한 티켓의 수량 또는 가액에 비례하여 예매 수수료를 부과할 수 있으며 회원등급에 따라 예매수수료를 차등 적용할 수 있습니다.</li>
                            <li>③ 영화예매서비스의 예매 수수료의 금액 및 수수료 부과 기준에 대해 "YES24영화예매서비스" 의 특정화면에 상시 게재하며, 그 내용에 변경이 있을 때에는 제8조에 정한 방법으로 회원에게 통지합니다.</li>
                            <li>④ 회사는 예매 완료의 증거로 "YES24영화예매서비스" 예매확인증을 회원에게 제공하며, 회원은 "YES24영화예매서비스"의 예매확인증에 기재된 내용을 숙지하여야 합니다.</li>
                            <li>⑤ 영화예매는 관람자 본인 예매가 원칙이며 극장현장에서 본인확인 후 티켓을 수령해야 합니다.</li>
                        </ul>
                        <h5>제23조 (영화예매의 변경, 취소)</h5>
                        <ul>
                            <li>① 회원은 "YES24영화예매서비스" 예매확인증에 기재된 예매내용(영화명, 극장명, 성인/학생여부, 예매티켓 수량, 상영일시 등) 또는 대금지급 방법을 변경하고자 하는 경우에는 당해 예매를 취소하고 새로 예매를 해야 합니다.</li>
                            <li>② 회원이 구매한 예매의 내용에 변동이 있는 경우에 회사는 지체 없이 회원에게 변경된 사항에 대해 통지해 주어야 합니다. 다만, 예매신청 시 등록된 회원의 연락처가 불명확하여 회사가 통지할 수 없었던 경우에 회사는 책임이 없습니다.</li>
                            <li>③ 극장사정에 의하여 상영시간표가 변경될 경우 회사는 극장과 협의하여 극장 또는 회사에서 회원에게 통지하도록 하며, 시간표 변경에 대한 책임은 해당 극장에 있습니다.</li>
                            <li>④ 영화예매의 취소는 회사의 규정된 시간 내에만 가능하며(이후에는 예매극장의 취소규정 적용) 취소와 관련된 구체적인 내용은 사이트 내에 공지합니다.</li>
                        </ul>
                        <h5>제24조 (영화예매의 보상규정)</h5>
                        <ul>
                            <li>① 회사는 회원이 구매한 예매내용이 극장에서 실제로 관람할 내용과 달랐음에도 불구하고 회사가 연락을 취하지 않은 경우, 취소마감 일시가 경과한 후에라도 취소 마감일 1개월 이내에는 해당 예매를 취소하고 환불 해 주어야 합니다.</li>
                            <li>② 입장권을 교환 받은 후 발생하는 상영취소, 지연, 상영물의 무단 변경 등 제반 문제에 대해서는 극장 측에 책임이 있으며, 해당극장의 보상규정을 따릅니다.</li>
                        </ul>


                        <h5>제25조 (영화예매권)</h5>
                        <ul>
                            <li>① 회사가 발행한 영화예매권의 재판매 및 환불은 불가합니다. 재판매 행위 적발 시 해당 회원의 영화예매권 사용 권한과 해당 영화예매권 고유번호에 대한 사용을 중단할 수 있으며, 재판매 행위로 인한 회사의 피해금액에 대해 해당 회원에게 2배의 손해배상을 청구할 수 있습니다.</li>
                            <li>② 부정하거나 불법적인 방법으로 거래하여 사용하여 발생한 피해는 회사가 책임지지 않습니다.</li>
                            <li>③ 회원 본인의 명의로 연간 24매까지 등록, 사용 가능하며, 24매라 함은 YES24영화예매권을 사용하여 예매 할 수 있는 좌석수가 총 24좌석이란 의미와 동일합니다.</li>
                            <li>④ 사용기간의 연장 및 변경은 불가합니다.</li>
                            <li>⑤ 영화가 지정된 예매권의 경우, 서비스제공자가 정한 사용조건의 사용기간에 한해 해당 영화가 상영되는 기간 내에만 사용가능하며 다른 영화예매 사용시 사용이 불가합니다.</li>
                            <li>⑥ 회원 귀책사유로 인한 예매권 고유번호의 도난, 분실, 유출에 대해서는 회사는 책임을 지지 않습니다.</li>
                        </ul>


                        <h5>제26조 (별사탕서비스)</h5>
                        <ul>
                            <li>① 회사에서 제공하는 '별사탕(starcandy.yes24.com)'은 비현금성 무료포인트로서 일체의 보상정책에 따르지 않습니다.</li>
                            <li>② 별사탕은 회사의 영화와 공연서비스에서 운영하는 포인트로, starcandy.yes24.com에서 정한 범위(별사탕 소개 참조)내에서 적립 및 소진(경품응모, 게임 등) 할 수 있습니다.</li>
                            <li>③ 별사탕서비스는 회사의 사정에 의하여 정책이 변경될 수 있으며, 사전 예고 후에 서비스가 종료될 수 있습니다. 서비스 종료 시에 각 회원별로 보유한 별사탕은 회원의 동의 없이 자동 소멸됩니다.</li>
                        </ul>


                        <h5>제27조 (다운로드서비스)</h5>
                        <ul>
                            <li>① 회사가 제공하는 다운로드 서비스는 컴퓨터에서만 제공하여 회사가 제공하는 통합설치프로그램 소프트웨어를 설치하여야 이용하실 수 있으며 스트리밍 서비스(PLAY24)는 컴퓨터를 제외한 모바일 및 기타 디바이스에서만 이용하실 수 있습니다.</li>
                            <li>② 컨텐츠는 회사가 '컨텐츠제공자'로부터 사용권한을 부여 받은 것으로 컨텐츠 제공자 또는 원 권리자에 모든 권리가 귀속되며 저작권 법령 또는 관련 지적 재산권 법률 및 조약에 의해 보호됩니다.</li>
                            <li>③ 회원은 회사가 정한 이용조건에 따라 구매한 다운로드 및 스트리밍 컨텐츠를 이용할 수 있는 이용권만을 가지며 회사, 컨텐츠 제공자 또는 원 권리자는 본 이용계약으로 다운로드 및 스트리밍 컨텐츠 상의 소유권 내지 어떠한 권리 또는 이익을 회원에게 양도하지 않습니다.</li>
                            <li>④ 회원은 개인적인 용도, 비영리적인 목적으로만 다운로드 및 스트리밍 컨텐츠를 이용할 수 있습니다. 회원은 그 밖의 목적으로 다운로드 및 스트리밍 컨텐츠를 이용할 수 없으며 모든 관련 권한은 컨텐츠 제공자 또는 해당 원 권리자에 귀속됩니다.</li>
                            <li>⑤ 재배포, 판매, 임대, 대여, 양도 또는 달리 제3자에 재사용권을 부여하거나 다운로드 및 스트리밍 컨텐츠를 보호하고 있는 기술을 우회하거나 제거하려는 시도를 해서는 아니 되며, 다운로드 및 스트리밍 컨텐츠가 저장된 컴퓨터 또는 모바일 디바이스를 통해 파일을 사람이 읽을 수 있는 형식으로 디컴파일, 수정, 복제, 역설계, 조작, 분리해서는 안 됩니다.</li>
                            <li>⑥ 해외지역에서의 구매는 제한되어 있으며 회원은 구매한 영화컨텐츠를 해외지역으로 전송할 수 없습니다.</li>
                            <li>⑦ 국내 법에 의해 허용되는 최대한의 범위 내에서 회사, 컨텐츠 제공자 및 원 권리자는 컨텐츠의 상업성, 특정 목적에 대한 적합성, 품질, 비침해 등에 대한 보증이나 조건을 포함하여 여타 어떠한 명시적, 묵시적 보증이나 조건도 부인합니다. 또한, 회사, 컨텐츠 제공자 및 원 권리자는 본 서비스와 관련하여 간접적, 우발적, 특수적, 징벌적 또는 결과적 손해나 이익에 대해 책임지지 않습니다.</li>
                            <li>⑧ 회원이 컨텐츠를 구입 후 7일 이내에, 미사용한(다운로드 및 스트리밍 0건) 컨텐츠에 대해서 청약을 철회할 수 있습니다. 회원의 청약 철회 접수 시 회사는 다운로드 시도 횟수를 파악하여 적절한 조치를 취해야 합니다.</li>
                            <li>⑨ 비정상적인 방법 등 부당한 이용이 발견될 때는 회원에게 사전 고지하고 회원의 계정을 정지할 수 있습니다. 다만 정상적인 서비스 운영을 위해 긴급한 조치가 요구되는 경우에는 계정을 정지한 후에 고지할 수 있습니다.</li>
                            <li>⑩ 제재가 있는 경우 회사는 회원의 부당한 행위자료를 제시하며, 회원은 회사에게 소명하여야 합니다. 다만 회원이 자신의 고의나 과실이 없음을 입증한 경우, 회사는 계정을 정지한 기간만큼 이용기간을 연장해야 합니다.</li>
                            <li>⑪ 회사는 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을 환불하여야 합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.</li>
                            <li>⑫ 회사의 책임 있는 사유로 과오금이 발생한 경우 회사는 계약비용, 수수료 등에 관계없이 과오금 전액을 환불합니다. 다만, 회원의 책임 있는 사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서 회원이 부담하여야 합니다.</li>
                            <li>⑬ 회사는 회원이 주장하는 과오금에 대해 환불을 거부할 경우에 정당하게 이용대금이 부과되었음을 입증할 책임을 집니다.</li>
                            <li>⑭ 회사는 과오금의 환불절차를 [디지털 컨텐츠 이용자 보호지침]에 따라 처리합니다.</li>
                        </ul>


                        <h4>제10장 공연 서비스</h4>

                        <h5>제28조 (공연예매)</h5>
                        <ul>
                            <li>① 회사는 전화와 인터넷, 예매처를 통해 공연, 행사 등의 예약서비스를 제공하며, 예약 가능 기간은 회원이 사용하는 예약 서비스의 선택, 결제수단, 배송방법에 따라 다르며, 이는 회사 사이트의 이용 안내에 명시된 내용을 따릅니다.</li>
                            <li>② 회원은 누구나 예약서비스를 이용할 수 있으나, 상품에 따라 본인인증(실명인증)한 회원만 예약서비스를 이용할 수 있도록 제한할 수 있으며, 이 경우 사전에 상품 안내 페이지에 별도로 공지할 수 있습니다.</li>
                        </ul>


                        <h5>제29조 (티켓 예매 수수료)</h5>
                        <ul>
                            <li>① 회사는 회사가 제공하는 예약 서비스에 대해 예매수수료를 부과합니다. 상품의 카테고리와 예매 채널(인터넷/고객센터 등)에 따라 차등화된 예매 수수료를 받고 있으며, 예매 수수료 정책은 회사의 티켓 예매 서비스 상에 별도로 게시할 수 있고, 제 8 조에 따라 회원에 대한 통지 후 변경될 수 있습니다.</li>
                            <li>② 상품 별로 별도로 정하지 않는 한 예매수수료는 제 31조에 따른 취소의 경우에도 예매일 익일부터는 환급하지 아니합니다.</li>
                        </ul>


                        <h5>제30조 (허위/불법 구매에 대한 규제)</h5>
                        <ul>
                            <li>① 회사는 선량한 이용자의 보호를 위하여 다음에 해당하는 이용자에 대하여 사전 통보 없이 일정한 기간을 정하여 예매 제한 또는 해당 예매 건에 대한 예매 취소 조치를 취할 수 있습니다.</li>
                            <ul>
                                <li>1. 최근 30일 이내, 50매 이상의 티켓을 취소한 이력이 있는 경우 일정기간 결제수단 제한</li>
                                <li>2. 반복적인 다량 구매 후 취소하는 경우(예: 재판매를 위하여 일정기간 동안 다량 구매 후 취소를 반복하는 경우) 예매 제한</li>
                                <li>3. 부정한 방법(예: 비정상적인 방법을 통한 예매 시도)을 통한 예매의 경우</li>
                                <li>4. 기타 이에 준하는 부정한 이용으로 판단되는 경우</li>
                            </ul>
                            <li>② 상기 1항의 경우 회사는 해당 이용자에게 일정 기간 소명기한을 부여하며 그 기한 내 이용자가 소명서를 제출, 그 내용이 합당하다고 판단되면 해당 조치를 해지할 수 있습니다.</li>
                            <li>③ 이용자가 본 조에 행하는 행위를 지속적으로 행함으로써 회사 또는 다른 이용자에게 피해를 주는 것으로 판단되는 경우 회사는 제7조에 따라 회원자격을 제한, 정지 및 상실시킬 수 있습니다.</li>
                        </ul>


                        <h5>제31조 (티켓 배송)</h5>
                        <ul>
                            <li>① 회사는 회원이 구매한 티켓에 대해 배송 수단, 배송 비용, 배송 기간 등을 명시합니다.</li>
                            <li>② 배송 소요 기간은 결제가 완료된 익일에 발송하며 도착일은 지방 포함 7일 이내의 기준이 적용됩니다. 단, 공휴일 및 기타 휴무일 또는 천재지변 등의 불가항력적 사유가 발생하는 경우 배송 소요 기간이 길어질 수 있습니다.</li>
                            <li>③ 특정 상품의 경우 배송일을 지정하여 특정 일자에 배송을 할 수 있으며, 이 경우 상품 안내 페이지에 별도로 공지할 수 있습니다.</li>
                            <li>④ 티켓 배송료는 달리 정함이 없는 한 회원이 부담하며 그 요금은 빠른 등기우편료 등의 비용을 기준으로 합니다.</li>
                        </ul>

                        <h5>제32조 (예매 티켓에 대한 취소, 환불)</h5>
                        <ul>
                            <li>① 예매한 티켓의 취소는 판매 취소 마감시간 전까지 인터넷, 고객센터 를 통해 직접 취소할 수 있습니다. 단, 복합결제(두 가지 이상의 결제 수단)로 결제를 한 경우에는 부분취소가 제한될 수 있습니다.</li>
                            <li>② 회사는 판매 및 취소 마감 시간 기준을 개별 상품에 따라 달리 정할 수 있으며, 행사 주최사에 의해 변경될 수 있습니다.</li>
                            <li>③ 구매자가 배송 서비스를 이용해 티켓을 수령한 경우 지정된 취소 마감 시한까지 티켓을 회사로 반송, 도달하도록 하여야 취소가 가능합니다.</li>
                            <li>④ 회사는 관련 법률에 따라 예매 후 7일 이내에 취소하는 경우 취소수수료를 부과하지 아니합니다.</li>
                            <li>⑤ 제4 항의 기간 이외의 취소 시에는 개별적인 상품에 따라 취소수수료를 달리 정하고 해당 수수료를 공제할 수 있으며, 취소수수료 정책은 사전 공지 후 변경될 수 있습니다. 다만 달리 정하지 않은 경우 아래 기준에 따릅니다.</li>

                            <ul>
                                <li>1. 제 4 항에도 불구하고 취소의 시점이 소비자분쟁해결기준의 기한에 해당하는 경우 소비자분쟁해결기준의 보상규정에 따릅니다.</li>
                                <li>2. 취소의 시점이 공연일로부터 10일 이상 남은 경우에는 아래 기준의 취소수수료를 부과합니다.</li>
                                <ul>
                                    <li>- 뮤지컬, 콘서트, 클래식 등 : 4,000원</li>
                                    <li>- 연극, 전시 등 : 2,000원</li>
                                    <li>- 단, 상기 금액은 판매가격의 10%를 초과하지 아니합니다.</li>
                                </ul>
                                <li>3. 취소의 시점이 관람일 1일 전 ~ 9일 전인 경우 아래 기준의 취소수수료를 부과합니다.</li>
                                <ul>
                                    <li>- 관람일 9일 전 ~ 관람일 7일 전까지 : 티켓 금액의 10%</li>
                                    <li>- 관람일 6일 전 ~ 관람일 3일 전까지 : 티켓 금액의 20%</li>
                                    <li>- 관람일 2일 전 ~ 관람일 1일 전까지 : 티켓 금액의 30%</li>
                                    <li>- 단, 예매 후 7일 이내라도 취소시점이 관람일로부터 10일 이내라면 그에 해당하는 취소수수료가 부과됩니다.</li>
                                </ul>
                            </ul>
                            <li>⑥ 구매자가 예매 티켓을 분실, 훼손한 경우 회사는 해당 예매 취소 및 변경, 재발행을 허용하지 않을 수 있습니다.</li>
                            <ul>
                                <li>결제 방법에 따라 취소 절차가 다르게 적용될 수 있습니다.</li>
                                <ul>
                                    <li>1. 신용카드 결제 : 예매 취소 시 카드사의 부분취소 방식에 따라 기존 결제내역을 전체 취소하고 취소 시점에 따라 취소수수료와 예매수수료 또는 배송료를 재승인합니다.</li>
                                    <li>2. 무통장 입금 결제 : 회사는 회원이 기재한 환불 계좌 정보(은행 계좌번호와 예금주 등)로 취소 수수료 등 기타 비용을 공제한 금액을 회원 환불 계좌에 입금합니다.</li>
                                    <li>3. YES머니, YES상품권 등 기타 예스24 결제 수단 : 회사는 예매 취소 시 바로 환급, 회원 계정으로 복원합니다. 단, 사용 기간이 만료된 결제 수단의 경우 복원되지 않습니다.</li>
                                </ul>
                            </ul>

                            <h5>제33조 (불법 거래에 대한 규제)</h5>
                            <ul>
                                <li>① 회사가 제공하는 예약 서비스가 아닌 재판매를 통한 개인간 거래 또는 비공식 업체에서의 구매에 대해 회사는 책임을 지지 않습니다.</li>
                                <li>② 불법 거래가 확인되는 경우 행사 주최사의 요청에 의해 구매가 자동 취소될 수 있으며, 이 경우 구매자에게 별도 통지합니다.</li>
                            </ul>


                            <h4>제11장 게시물과 커뮤니티</h4>
                            <h5>제34조 (게시물의 저작권)</h5>
                            <ul>
                                <li>① 회사가 작성한 게시물의 저작권은 회사에 귀속되며 이용자는 회사의 동의 없이 이를 무단으로 사용할 수 없습니다.</li>
                                <li>② 회사는 서비스 내 모든 게시물에 대한 게시권을 갖습니다. 다음의 경우 회사는 저작권자의 사전 동의 없이 게시물을 이용할 수 있으며 원본의 내용을 크게 해치지 않는 범위에서 수정, 재가공할 수 있습니다.</li>
                                <ul>
                                    <li>1. 회사가 제공하는 사이트 내에서의 컨텐츠 홍보를 수행하는 경우</li>
                                    <li>2. 회사가 제휴 관계를 맺고 있는 사이트 내에서 서비스를 안내하거나 홍보하는 경우</li>
                                    <li>3. 회사의 홍보 목적으로 미디어, 통신사 등에 컨텐츠를 제공하는 경우.</li>
                                </ul>
                                <li>③ 회사가 정한 절차에 따라 이용자가 동의 의사를 밝힌 경우 회사는 해당 이용자가 저작권을 소유한 저작물을 제 3자에게 제공할 수 있습니다.</li>
                                <li>④ 이용자의 게시물이 타인의 저작권을 침해할 경우 회사는 이에 대한 민, 형사상의 책임을 지지 않습니다. 이용자의 저작권 침해 행위로 인해 회사가 타인으로부터 이의 제기를 받는 경우 이용자는 회사의 면책을 위해 노력하여야 하며, 회사가 면책되지 못하는 경우 이용자는 그로 인한 회사의 손해를 부담하여야 합니다.</li>
                            </ul>


                            <h5>제35조 (게시물의 관리)</h5>
                            <ul>
                                <li>① 모든 게시물에 대한 책임은 게시한 자에게 있으며 게시물이 전달하는 정보의 신뢰도, 정확성 등에 대해서 회사는 책임지지 않습니다.</li>
                                <li>② 이용자가 직접 삭제한 게시물은 시스템 상에서 완전히 삭제되며 회사는 이를 별도로 보관하거나 복구할 책임을 지지 않습니다.</li>
                                <li>③ 게시물이 아래 각 호에 해당할 경우 회사가 이를 삭제, 이동하거나 등록을 거부할 수 있고 서비스 내 표시 및 공람을 허용하지 않을 수 있습니다.</li>
                                <ul>
                                    <li>1. 공공질서와 미풍양속을 저해하는 내용</li>
                                    <li>2. 폭력적이거나 저속하고 음란한 내용</li>
                                    <li>3. 불법 복제, 해킹, 기타 현행법을 위반하거나 저촉할 우려가 있는 내용</li>
                                    <li>4. 특정 개인이나 단체를 모욕하거나 명예를 훼손하고 피해를 줄 의도를 담고 있는 내용</li>
                                    <li>5. 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용</li>
                                    <li>6. 타인의 지적 재산권, 초상권 등 권리를 침해하는 내용</li>
                                    <li>7. 광고, 홍보, 판촉 등 영리를 목적으로 한 상업적 내용</li>
                                    <li>8. 사적인 정치적 판단이나 종교적 견해로 이용자간 위화감을 조장하는 내용</li>
                                    <li>9. 서비스 각 영역이 규정하고 있는 운영 원칙에 어긋나거나 부합하지 않는 내용</li>
                                    <li>10. 이해 당사자의 삭제 요청이 있거나 회사가 피소, 고발될 수 있는 근거를 제공하는 경우</li>
                                    <li>11. 동일한 내용을 반복 게시하는 등 다른 이용자의 서비스 이용에 지장을 초래하는 경우</li>
                                    <li>12. 회사의 원활한 서비스 제공을 방해하는 경우</li>
                                    <li>13. 범죄와 결부된다고 객관적으로 인정되는 경우</li>
                                    <li>14. 기타 관계법령에 위배된다고 판단되는 내용</li>
                                </ul>
                            </ul>


                            <h5>제36조 (자료의 보관)</h5>
                            <ul>
                                <li>① 이용자가 서비스를 이용하며 축적한 데이터에 대한 보관 책임은 이용자에게 있으며, 무료 서비스의 장애, 제공 중단, 자료 멸실, 삭제, 변조 등으로 인한 손해에 대해서 회사는 원상 복구에 최선을 다할 의무만을 지닙니다.</li>
                                <li>② 회원이 서비스 이용 계약을 해지하였을 경우 회원의 게시물은 모두 삭제되며 삭제된 자료는 복구할 수 없습니다. 단, 스크랩 기능 등을 통해 복제, 전송된 컨텐츠는 삭제 대상에 포함되지 않습니다.</li>
                                <li>③ 회사가 본 약관 제34조에 의거하여 게시물을 삭제하는 경우 삭제된 자료에 대해서 복구할 책임을 지지 않습니다.</li>
                            </ul>


                            <h5>제37조 (회원의 권리와 의무)</h5>
                            <ul>
                                <li>① 회원은 자신의 블로그 개설 및 운영 취지에 반하는 게시물에 대하여 게재를 거부하거나 삭제할 수 있습니다.</li>
                                <li>② 회원은 일정 기간 블로그 서비스를 이용하지 않거나 이용을 원하지 않는 경우 '블로그일시정지'를 선택할 수 있습니다. 일시정지 된 블로그의 컨텐츠는 모두 보관되며 이를 열람, 수정, 삭제하려면 일시정지 상태를 회원이 직접 해제해야 합니다.</li>
                                <li>③ 회원은 애드온2 서비스를 이용함에 있어 아래의 행위를 하여서는 안 되며, 해당 행위를 하는 경우에는 서비스 혜택을 지급받지 못하거나 부정한 방법의 결과로 지급받은 금액에 대해서 즉시 반납해야 합니다.</li>
                                <ul>
                                    <li>1. 구매자가 악의적인 이용(반복주문 뒤 취소와 같은 부정적립 행위)</li>
                                    <li>2. 여러 회원 ID를 이용해 부정 적립하는 경우</li>
                                    <li>3. 자신의 애드온 박스나 리뷰를 통해서 자가 구매를 하는 경우</li>
                                </ul>
                            </ul>


                            <h4>제12장 기타</h4>

                            <h5>제38조(연결"몰"과 피연결"몰" 간의 관계)</h5>
                            <ul>
                                <li>① 상위"몰"과 하위 "몰"이 하이퍼 링크(예: 하이퍼 링크 대상은 문자, 그림 및 동화상 등을 포함함)방식 등으로 연결된 경우, 전자를 연결 "몰"(웹사이트)이라고 하고 후자를 피연결 "몰" (웹사이트)이라고 합니다.</li>
                                <li>② 연결 "몰"은 피연결 "몰"이 독자적으로 제공하는 재화.용역에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을 연결 "몰"의 사이트에서 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다.</li>
                            </ul>

                            <h5>제39조(분쟁해결)</h5>
                            <ul>
                                <li>① 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치.운영합니다.</li>
                                <li>② 회사는 이용자가 제출하는 불만사항 및 의견이 있을 경우 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해야 합니다.</li>
                                <li>③ 회사와 이용자간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시.도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</li>
                            </ul>

                            <h5>제40조(재판권 및 준거법)</h5>
                            <ul>
                                <li>①회사와 이용자간에 발생한 전자거래 분쟁에 관한 소송은 민사소송법상의 관할법원 또는 서울중앙지방법원에 제기합니다.</li>
                                <li>②회사와 이용자간의 서비스 이용 계약, 회원 상호 간의 물품거래에는 대한민국 법령이 적용됩니다.</li>
                            </ul>


                            <h4>제13장 특별규정</h4>

                            <h5>제41조(법령 및 준용)</h5>
                                <p>이 약관에 명시하지 않은 사항은 관련법령과 회사의 규정 및 기타 상관례에 따릅니다.</p>

                                <h5>제42조(회원의 적립금 운영)</h5>
                                <ul>
                                    <li>① 적립금은 포인트 개념의 YES포인트와 화폐개념의 머니로 구성됩니다.</li>
                                    <li>② YES포인트란 회사에서 상품을 구매할 경우 각 상품별로 설정되어 누적되는 점수로써, 회사가 회원에게 제공하는 회원보상체계의 일종이나, 그 자체로는 결제에 사용될 수 없습니다.</li>
                                    <li>③ YES머니란 제2항의 YES포인트가 회사가 정한 일정 기준에 도달한 경우, 회원이 지불수단으로 사용하기 위하여 전환과정을 거쳐 현금처럼 사용할 수 있는 화폐개념입니다. 단, 현금으로 환불되지 않습니다.</li>
                                    <li>④ 회사의 운영정책에 따라 상품별 적립금 부여 그리고 적립금 사용방법과 가능시점 및 분할사용가능 여부가 다를 수 있습니다.</li>
                                    <li>⑤ 회원이 적립금을 부당하게 취득한 증거가 있을 때에는 회사는 사전통지 없이 회원의 적립금을 삭제할 수 있으며 이와 관련하여 회원 자격을 제한할 수 있습니다.</li>
                                    <li>⑥ 회원탈퇴 또는 회원자격상실 시 잔액 여부와 상관없이 회원의 적립금은 소멸되며 타인에게 양도할 수 없습니다.</li>
                                    <li>⑦ 회원이 보유한 포인트보다 사용한 포인트가 많아서 마이너스 포인트가 발생하는 경우, 회원은 회사에 마이너스 포인트를 해소시켜야 할 채무가 있고 회사는 회원에게 마이너스 포인트 해소를 요청할 채권이 있다.</li>
                                </ul>


                                <h5>제43조(예치금)</h5>
                                <ul>
                                    <li>① 회원이 직접 환불 접수할 때 까지 해당 금액은 임시로 보관됩니다.</li>
                                    <li>② 회원의 요청에 따라 예치금의 전부 또는 일부를 은행 계좌로 입, 출금 할 수 있습니다.</li>
                                    <li>③ 예치기간은 부여하지 않고 회원 탈퇴(계약해지) 시 소멸 됩니다.</li>
                                </ul>

                                <h5>제44조(휴면계정)</h5>
                                <ul>
                                    <li>① 회원이 1년 동안 회사를 이용하지 않았을 경우 원활한 회원관리를 위하여 휴면계정으로 처리하여 회원자격으로의 활동과 해당 계정으로 지급된 일체의 적립금의 사용을 제한 할 수 있습니다. 다만, 회원이 회사가 정한 절차에 따라 휴면계정 철회를 요청하면 회사는 종전의 회원자격과 적립금을 다시 활성화하여야 합니다.</li>
                                    <li>② 휴면계정으로 처리된 후 일정기간이 지나면 회사는 제8조에 정한 방법으로 통지 후 해당 회원의 적립금을 소멸할 수 있습니다.</li>
                                </ul>

                                <h5>제45조(ID 이관 정책)</h5>
                                <ul>
                                    <li>다음 각호와 같이 회사에서 제공 및 발행하는 재화와 용역, 적립금 등은 기본적으로 다른 ID로의 이관이 불가합니다.</li>
                                    <li>① YES포인트, YES머니 등의 모든 적립금</li>
                                    <li>② eBook, 시프트북스 등의 모든 디지털상품</li>
                                    <li>③ 영화, 공연예매내역</li>
                                </ul>

                                <h5>제46조 (개별약관)</h5>
                                <ul>
                                    <li>① 이 약관은 예스이십사 주식회사와 회원간에 성립되는 서비스이용계약의 기본약정입니다. 예스이십사 주식회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 "개별약관"이라고 합니다)을 정하여 미리 공지할 수 있습니다. 회원이 이러한 개별약관에 동의하고 특정 서비스를 이용하는 경우에는 개별약관이 우선적으로 적용되고, 이 약관은 보충적인 효력을 갖습니다.</li>
                                    <li>② 예스이십사 주식회사는 필요한 경우 서비스 이용과 관련된 세부적인 개별내용(이용정책 등)을 정하여 사이트 등을 통하여 공지할 수 있습니다.</li>
                                </ul>

                                <h4>부칙</h4>
                                <h5>제1조 (시행일자)</h5>
                                <p>본 약관은 2018년 03월 20일부터 시행합니다.</p>
                        </ul>
                    </section>
                </div>
            </div>
        );
    };
};

export default PolicyPage;
